import React from "react";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { signIn } from "../../redux/manager/managerActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MUILink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <MUILink color="inherit" href="https://www.kind-soft.com/">
        KINDSOFT Inc.
      </MUILink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();
const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const manager = useSelector((state: RootState) => state.manager); // Get the current user state

  const [rememberMe, setRememberMe] = React.useState<boolean>(
    localStorage.getItem("rememberedId") !== null
    // 초기화할 때 rememberMe의 초기 상태를 로컬스토리지에 ID가 있으면 true
  );

  const [storedId, setStoredId] = React.useState<string | null>(
    localStorage.getItem("rememberedId") //초기값설정
  );

  React.useEffect(() => {
    console.log("로그인페이지-렌더링");
    const rememberedId = localStorage.getItem("rememberedId");
    if (rememberedId) {
      setStoredId(rememberedId);
    }
    console.log("rememberedId", rememberedId);
  }, [navigate, storedId]);

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const managerLoginData = {
      managerId: data.get("managerId") as string,
      password: data.get("password") as string,
      // otp: data.get("otp") as string,
    };

    await dispatch(signIn(managerLoginData, navigate));

    if (rememberMe) {
      localStorage.setItem("rememberedId", managerLoginData.managerId);
    } else {
      localStorage.removeItem("rememberedId");
    }
  };

  return (
    // <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" sx={{ minWidth: 228 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            marginBottom:"30px"
          }}
        >
          <Typography component="h1" variant="h5">
            로그인
          </Typography>
          <Box
            component="img"
            sx={{
              position:'absolute',
              left:'100%',
              marginLeft:'20px',
              width: 120, // 너비 설정
              // height: 65, // 높이 설정
              // objectFit: "cover", // 이미지가 박스에 맞게 조정
            }}
            alt={""}
            // src="/images/JJokomi-removebg1.png"
            // src="/images/ci2-removebg-preview.png"
            src="/images/kiny_jjokomi-removebg-preview_waifu2x_art_noise1_scale.png"
            // src="/images/kiny1-removebg-preview.png"
          />
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 8 }}
        >
          <TextField
            defaultValue={storedId} // 로컬 스토리지에서 가져온 Id 사용
            margin="normal"
            required
            fullWidth
            id="managerId"
            label="ID"
            name="managerId"
            autoComplete="id"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          {/* <TextField
            margin="normal"
            // required//필수입력이냐 아니냐
            fullWidth
            name="otp"
            label="OTP"
            id="otp"
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeChange}
                value="remember"
                color="primary"
              />
            }
            label="아이디 저장"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            로그인
          </Button>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red",
              }}
            >
              <MUILink href="/find-manager-id" variant="body2">
                ID 찾기
              </MUILink>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "1px solid red",
              }}
            >
              <MUILink href="/sign-up" variant="body2">
                {"회원가입"}
              </MUILink>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
    // </ThemeProvider>
  );
};

export default SignInPage;
