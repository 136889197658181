import UpdatePwPage from "../pages/admin/updatePwPage";
import AdminMenuPage from "../pages/admin/AdminMenuPage";
import MainPage from "../pages/main/MainPage";
import InstallPage from "../pages/install/InstallPage";

const mainRoutes = [
  {
    path: "/",
    component: MainPage,
  },
  {
    path: "/install",
    component: InstallPage,
  },
  {
    path: "/admin",
    component: AdminMenuPage,
    allowedRoles: ["1"],
  },
  {
    path: "/update-pw",
    component: UpdatePwPage,
    allowedRoles: ["1"],
  },
];

export default mainRoutes;
