import React from "react";
import { Box, Typography, Grid, Container, Divider } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

interface FooterLinkProps extends LinkProps {
  to: string;
}

const FooterLink: React.FC<FooterLinkProps> = ({ to, children }) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        color: "#3f3a39",
        display: "block",
        marginBottom: "8px", // 각 링크 사이에 간격 추가
        transition: "color 0.3s"
      }}
      className="footer-link"
    >
      <Typography
        component="span"
        sx={{
          fontFamily: "'NanumSquareR'", // 글꼴 변경
          // fontSize: "1rem",
          '&:hover': {
            color: "#4B95D1", // 호버 시 파란색으로 변경
          }
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        // bgcolor: "#003366",
        // bgcolor: "#4B95D1",
        // bgcolor: "#002c6a",
        // color: "#3f3a39",
        color: "#3f3a39",
        mt: 5,
        minHeight: "350px", // 높이 설정
        display: "flex", // Flexbox를 사용
        flexDirection: "column", // 세로 방향 배열
        justifyContent: "center",
      }}
    >
      <Box sx={{ py: 4, minWidth: 261 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 0,
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <Box sx={{ width: "160px" }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'NanumSquareEB'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                제품 소개
              </Typography>
              <Divider
                sx={{
                  bgcolor: "#3f3a39",
                  borderWidth: 1,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/introduce/logvisor">LogVisor</FooterLink>
              <FooterLink to="/introduce/function">주요 기능</FooterLink>
              {/* <FooterLink to="/introduce/competitive">경쟁력</FooterLink> */}
              <FooterLink to="/introduce/video">제품소개 영상</FooterLink>
              <FooterLink to="/introduce/experience">체험하기</FooterLink>
            </Box>
            <Box sx={{ width: "160px" }}>
              {" "}
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'NanumSquareEB'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                요금제
              </Typography>
              <Divider
                sx={{
                  bgcolor: "#3f3a39",
                  borderWidth: 1,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/payment-system">요금제 비교</FooterLink>
            </Box>
            <Box sx={{ width: "160px" }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'NanumSquareEB'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                관리자
              </Typography>
              <Divider
                sx={{
                  bgcolor: "#3f3a39",
                  borderWidth: 1,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/dashboard">대쉬 보드</FooterLink>
              <FooterLink to="/user-register">사원 등록</FooterLink>
              <FooterLink to="/user-list/1">사원 관리</FooterLink>
              <FooterLink to="/logs-list?page=1">로그 조회</FooterLink>
            </Box>
            <Box sx={{ width: "160px" }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontFamily: "'NanumSquareEB'",
                  fontWeight: 900,
                  // fontSize: "28px",
                  // color: "MediumBlue",
                  // marginBottom: 10,
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                고객 지원
              </Typography>
              <Divider
                sx={{
                  bgcolor: "#3f3a39",
                  borderWidth: 1,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              />
              <FooterLink to="/notice-board-list?page=1">공지사항</FooterLink>
              <FooterLink to="/technical-board-register">기술 문의</FooterLink>
              <FooterLink to="/payment-board-register">결제 문의</FooterLink>
              <FooterLink to="/install">제품 설치</FooterLink>
            </Box>
          </Box>
          <Grid container justifyContent="flex-end">
            <Grid textAlign="right">
              <img
                src="/images/main2/08 인트로/08 인트로 01.png"
                alt="Logo"
                style={{ maxWidth: "150px", marginBottom: 20 }}
              />
              <Typography
                variant="body1"
                sx={{ fontFamily: "'NanumSquareEB'",fontSize:'20px', mt: 1 }}
              >
                (주)카인드소프트
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'NanumSquareR'",
                }}
              >
                대표자 : 한규명 | 사업자 등록번호 : 452-81-02149
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'NanumSquareR'",
                }}
              >
                경기도 수원시 영통구 광교중앙로 170, A동 1707, 1708호
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'NanumSquareR'",
                }}
              >
                T 031 308 7272 | F 0303 3444 7272
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'NanumSquareR'",
                }}
              >
                개인정보보호책임자 : 진은수 (kind@kind-soft.com)
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
