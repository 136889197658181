import ExperienceButton from "../../components/ExperienceButton";
import { Check, CheckBox, Done } from "@mui/icons-material";
import { Box, Button, CardMedia, Container, Typography } from "@mui/material";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const FunctionPage = () => {
  const navigate = useNavigate();
  // 상태를 사용하여 이미지 변경
  const [hovered1, setHovered1] = React.useState(false);
  const [hovered2, setHovered2] = React.useState(false);
  const [hovered3, setHovered3] = React.useState(false);

  // const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  // 여기에서 타입을 명확하게 지정합니다.
  // `RefObject<HTMLDivElement>[]`으로 명시적 타입 설정
  const sectionRefs = useRef<RefObject<HTMLDivElement>[]>([]);

  // 배열을 생성하여 각 요소에 대해 `createRef`를 호출
  for (let i = 0; i < 6; i++) {
    sectionRefs.current[i] = React.createRef();
  }

  const [isVisible, setIsVisible] = useState<boolean[]>(
    new Array(6).fill(false)
  );
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // sectionRefs.current에서 entry.target과 일치하는 ref의 index를 찾습니다.
          const index = sectionRefs.current.findIndex(
            (ref) => ref.current === entry.target
          );
          if (index !== -1 && entry.isIntersecting) {
            setIsVisible((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []); // 의존성 배열이 비어 있으므로 마운트 시 한 번만 실행됩니다.

  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box
          textAlign="center"
          marginTop={10}
          marginBottom={15}
          style={{ animation: "slide-up 1s ease-out" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // fontSize: "24px",
              fontFamily: "'NanumSquareNeoH'",
              fontWeight: "bold",
              color: "#3f3a39",
              // fontStyle: "italic",
              lineHeight: "1.5",
              "@media (max-width: 800px)": {
                fontSize: "20px", //
              },
            }}
          >
            <span style={{ color: "  #4B95D1" }}>인가된 사용자</span>에게만 PC
            사용을 허가하여<br></br> PC 부정사용을 선제적으로{" "}
            <span style={{ color: "  #4B95D1" }}>방어</span>할 수 있습니다.
          </Typography>
          <ExperienceButton></ExperienceButton>
        </Box>
      </Container>

      
        {/*  OTP개인인증으로 PC 로그인을 ~*/}
        <Box
          className={isVisible[0] ? "fade-in" : "hidden"}
          ref={sectionRefs.current[0]} // 이제 여기에서 ref를 할당
          // 페이드인 예정 0
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              mb: 10,
            },
          }}
        >
          <CardMedia
            component="img"
            image="/images/main2/05 인트로/05 인트로 01.png"
            alt="kind logvisor image"
            sx={{
              // maxHeight: 200,
              // maxWidth: 500,
              maxWidth: "10%",
              //  height:"auto",
              objectFit: "contain",
              "@media (max-width: 1000px)": {},
            }}
          />

          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: "45px", // 41pt에 해당하는 px 크기
              fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
              fontWeight: "900",
              color: "#4B95D1",
              lineHeight: "1.5",
              marginBottom: 5,
              "@media (max-width: 800px)": {
                fontSize: "25px", // 작은 화면에서는 적절히 조정
              },
            }}
          >
            OTP 개인인증으로 PC 로그인을 안전하게
          </Typography>
          <Box
            className="container"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              // gap:5,
              "@media (max-width: 800px)": {
                flexDirection: "column", // 화면 너비가 600px 이하일 때 세로로 정렬
                alignItems: "center",
              },
              // mb: -15,
            }}
          >
            <CardMedia
              component="img"
              image="/images/main2/05 인트로/05 인트로 03.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "35%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1000px)": {
                  order: 1, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                  marginBottom: 5,
                  maxWidth: "50%",
                },
                "@media (max-width: 800px)": {
                  order: 1, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                  marginBottom: 5,
                  maxWidth: "60%",
                },
              }}
            />

            <CardMedia
              component="img"
              image="/images/main2/05 인트로/05 인트로 04.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "35%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1000px)": {
                  order: 2, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                  marginBottom: 5,
                  maxWidth: "50%",
                },
                "@media (max-width: 800px)": {
                  order: 2, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                  maxWidth: "60%",
                },
              }}
            />
          </Box>
        </Box>

        {/*2팩터인증으로 더욱 안전하게  */}

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 0,
            mb: 10,
          }}
        >
          <Box
            // 페이드인예정1
            className={isVisible[1] ? "fade-in" : "hidden"}
            ref={sectionRefs.current[1]} // 이제 여기에서 ref를 할당
            textAlign="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 01.png"
              alt="image-logvisor"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "80%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 800px)": {
                  maxWidth: "70%",
                },
              }}
            />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              2단계(2Factor) 인증으로 더욱 안전하게
            </Typography>
          </Box>

          {/* 1단계 */}
          <Box
            className={isVisible[2] ? "fade-in" : "hidden"}
            ref={sectionRefs.current[2]} // 이제 여기에서 ref를 할당
            textAlign="center"
            // 페이드인예정2
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 03.png"
              alt="1단계"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                },
              }}
            />
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 04.png"
              alt="2팩터 PC로그인 3단계 변화"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500, 
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 05.png"
              alt="모니터별 설명1"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />

            {/* 아래화살표 */}
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 06.png"
              alt="down-arrow"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "10%",
                  marginBottom: 5,
                },
              }}
            />
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "21px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "500",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              로그인 후 이력이 관리자 페이지에 누적
            </Typography>
          </Box>
          {/* 2단계 */}
          <Box
            textAlign="center"
            className={isVisible[3] ? "fade-in" : "hidden"}
            ref={sectionRefs.current[3]} // 이제 여기에서 ref를 할당
            // 페이드인예정3
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 08.png"
              alt="2단계"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 09.png"
              alt="opt입력 3단계"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                // marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  // marginBottom: 5,
                },
              }}
            />

            <Box
              className="container"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "20vw",
                "@media (max-width: 520px)": {
                  gap: "30vw",
                },
                mb: 5,
              }}
            >
              <Box></Box>

              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontSize: "18px", // 41pt에 해당하는 px 크기
                  fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                  fontWeight: "500",
                  color: "#3f3a39",
                  lineHeight: "1.5",
                  transform: "translate(80px, 0px)",
                  "@media (max-width: 1000px)": {
                    fontSize: "14px", // 작은 화면에서는 적절히 조정
                    transform: "translate(50px, 0px)",
                  },
                  "@media (max-width: 800px)": {
                    fontSize: "10px", // 작은 화면에서는 적절히 조정
                    transform: "translate(40px, 0px)",
                  },
                }}
              >
                MS OTP 6자리 입력
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontSize: "18px", // 41pt에 해당하는 px 크기
                  fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                  fontWeight: "500",
                  color: "#3f3a39",
                  lineHeight: "1.5",
                  transform: "translate(-90px, 0px)",
                  "@media (max-width: 1000px)": {
                    fontSize: "14px", // 작은 화면에서는 적절히 조정
                    transform: "translate(-50px, 0px)",
                  },
                  "@media (max-width: 800px)": {
                    fontSize: "10px", // 작은 화면에서는 적절히 조정
                    transform: "translate(0px, 0px)",
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "10px", // 작은 화면에서는 적절히 조정
                    transform: "translate(-50px, 0px)",
                  },
                }}
              >
                정상 로그인
              </Typography>
            </Box>

            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 11.png"
              alt="down-arrow"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "10%",
                  marginBottom: 5,
                },
              }}
            />
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "21px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "500",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              로그인 후 이력이 관리자 페이지에 누적
            </Typography>
          </Box>
          <Box
            textAlign="center"
            className={isVisible[4] ? "fade-in" : "hidden"}
            ref={sectionRefs.current[4]} // 이제 여기에서 ref를 할당
            // 페이드인예정4
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/*ms windows hello 적용시 생체인증 로그인 가능 */}
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 13.png"
              alt="image-logvisor"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />

            {/* <Typography
            variant="h4"
            component="h4"
            sx={{
              fontSize: "30px", // 41pt에 해당하는 px 크기
              fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
              fontWeight: "900",
              color: "#4B95D1",
              lineHeight: "1.5",
              marginBottom: 10,
              "@media (max-width: 800px)": {
                fontSize: "25px", // 작은 화면에서는 적절히 조정
              },
            }}
          >
            MS Windows Hello 적용시 생체인증 로그인 가능
          </Typography> */}
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontSize: "28px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoEB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  fontSize: "20px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              [다양한 생체정보 Device]
            </Typography>
            <Box
              className="container"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 5,
                "@media (max-width: 800px)": {
                  flexDirection: "column", // 화면 너비가 600px 이하일 때 세로로 정렬
                  alignItems: "center",
                },
                mb: 10,
              }}
            >
              <Box
                sx={{
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  position: "relative", // 자식 요소들의 위치를 겹치게 설정하기 위한 relative
                  maxWidth: "100%", // 부모 박스의 크기 설정
                  height: "auto", // 이미지 높이를 자율적으로 설정
                }}
              >
                <CardMedia
                  component="img"
                  image="/images/main2/06 인트로/06 인트로 14.png"
                  alt="장비들"
                  sx={{
                    // maxHeight: 500,
                    // maxWidth: 500,
                    // maxWidth: "100%",
                    maxWidth: "60%",
                    height: "auto",
                    objectFit: "cover",
                    "@media (max-width: 800px)": {
                      marginBottom: 5,
                    },
                  }}
                />
                <CardMedia
                  component="img"
                  image="/images/main2/06 인트로/06 인트로 15.png"
                  alt="장비설명"
                  sx={{
                    position: "absolute",
                    top: "70%", // 상단에서 50% 내려오게 조정 (필요에 따라 변경 가능)
                    left: "40%", // 좌측에서 50% 이동 (필요에 따라 변경 가능)
                    transform: "translate(-35%, -50%)", // 중앙에 배치
                    maxWidth: "60%", // 크기 조정 (필요에 따라 변경)
                    height: "auto", // 이미지 비율 유지
                    // maxHeight: 500,
                    // maxWidth: 500,
                    // maxWidth: "100%",
                    // height: "auto",
                    objectFit: "contain",
                    "@media (max-width: 1000px)": {
                      transform: "translate(-35%, -50%)", // 중앙에 배치
                    },
                    "@media (max-width: 800px)": {
                      transform: "translate(-35%, -50%)", // 800px 이하일 때
                    },
                  }}
                />
              </Box>

              <CardMedia
                component="img"
                image="/images/main2/06 인트로/06 인트로 16.png"
                alt="right-arrow"
                sx={{
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "1%",
                  // height: "auto",
                  transform: "translate(-500%, 0%)",
                  objectFit: "contain",
                  "@media (max-width: 800px)": {
                    transform: "translate(0%, 0%)",
                    maxWidth: "5%",
                    marginBottom: 5,
                  },
                }}
              />
              <CardMedia
                component="img"
                image="/images/main2/06 인트로/06 인트로 17.png"
                alt="로그인 모니터"
                sx={{
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "25%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 800px)": {
                    marginBottom: 5,
                    maxWidth: "60%",
                  },
                }}
              />
            </Box>
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 18.png"
              alt="down- arrow"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "10%",
                  // marginBottom: 5,
                },
              }}
            />
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "21px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "500",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              로그인 후 이력이 관리자 페이지에 누적
            </Typography>
          </Box>
        </Box>

        {/*귀사의 환경에 적합한 방식을 선택하세요*/}
        <Box
          className={isVisible[5] ? "fade-in" : "hidden"}
          ref={sectionRefs.current[5]} // 이제 여기에서 ref를 할당
          sx={{
            // 페이드인예정5
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{
              fontSize: "48px", // 41pt에 해당하는 px 크기
              fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
              fontWeight: "900",
              color: "#4B95D1",
              lineHeight: "1.5",
              marginBottom: 8,
              "@media (max-width: 800px)": {
                fontSize: "30px", // 작은 화면에서는 적절히 조정
              },
              "@media (max-width: 500px)": {
                fontSize: "25px", // 작은 화면에서는 적절히 조정
              },
            }}
          >
            귀사의 환경에 적합한 방식을 선택하세요
          </Typography>
          <Box
            className="container"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 5,
              "@media (max-width: 800px)": {
                flexDirection: "column", // 화면 너비가 600px 이하일 때 세로로 정렬
                alignItems: "center",
              },
              mb: 5,
            }}
          >
            <Box
              sx={{
                maxWidth: "35%",
                // border: "20px solid transparent", // 기본 테두리 크기
                border: "20px solid transparent", // 기본 테두리 크기
                borderImage: `url('${encodeURI(
                  "/images/main2/07 인트로/07 인트로 02.png"
                )}') 30 round`,

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                "@media (max-width: 800px)": {
                  maxWidth: "70%",
                },
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  fontSize: "41px", // 41pt에 해당하는 px 크기
                  fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                  fontWeight: "900",
                  color: "#4B95D1",
                  lineHeight: "1.5",
                  marginBottom: 2,
                  "@media (max-width: 1000px)": {
                    fontSize: "30px", // 작은 화면에서는 적절히 조정
                  },
                }}
              >
                임대형
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontSize: "23px",
                  fontFamily: "'NanumSquareR'",
                  fontWeight: "600",
                  color: "#3f3a39",
                  lineHeight: "1.5",
                  marginBottom: 10,
                  "@media (max-width: 1000px)": {
                    fontSize: "18px", // 작은 화면에서는 적절히 조정
                  },
                }}
              >
                도입 부담이 없는 임대형 SaaS
                <br /> (Software as a Service)
              </Typography>

              <CardMedia
                component="img"
                image="/images/main2/07 인트로/07 인트로 05.png"
                alt="image-logvisor"
                sx={{
                  // maxWidth: 500,
                  maxWidth: "70%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1000px)": {
                    marginBottom: 5,
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                maxWidth: "35%",
                // border: "20px solid transparent", // 기본 테두리 크기
                // border: "20px solid transparent", // 기본 테두리 크기
                backgroundImage: `url('${encodeURI(
                  "/images/main2/07 인트로/07 인트로 06.png"
                )}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                border: "1px solid white",
                borderRadius: "30px",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                "@media (max-width: 800px)": {
                  maxWidth: "70%",
                },
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  fontSize: "41px", // 41pt에 해당하는 px 크기
                  fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                  fontWeight: "900",
                  color: "white",
                  lineHeight: "1.5",
                  marginTop: 2,
                  marginBottom: 2,
                  "@media (max-width: 1000px)": {
                    fontSize: "30px", // 작은 화면에서는 적절히 조정
                  },
                }}
              >
                구축형
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontSize: "23px",
                  fontFamily: "'NanumSquareR'",
                  fontWeight: "600",
                  color: "#3f3a39",
                  lineHeight: "1.5",
                  marginBottom: 10,
                  "@media (max-width: 1000px)": {
                    fontSize: "18px", // 작은 화면에서는 적절히 조정
                  },
                }}
              >
                요구사항(커스터마이징) 적용이 가능한
                <br /> 구축형(On-Premise)
              </Typography>

              <CardMedia
                component="img"
                image="/images/main2/07 인트로/07 인트로 09.png"
                alt="구축형 이미지"
                sx={{
                  // maxWidth: 500,
                  maxWidth: "70%",
                  // height: "auto",
                  objectFit: "contain",
                  marginBottom: 2,
                  "@media (max-width: 1000px)": {
                    marginBottom: 2,
                  },
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 5,
                  // maxWidth: 300, // 이미지 최대 크기
                }}
                onClick={() => navigate("payment-system")} // 제품 소개 페이지로 이동
                onMouseEnter={() => setHovered3(true)} // 마우스가 올라갈 때
                onMouseLeave={() => setHovered3(false)} // 마우스가 벗어날 때
              >
                <CardMedia
                  component="img"
                  image={
                    hovered3
                      ? "/images/main2/07 인트로/07 인트로 10(색변화).png" // 마우스 오버 시 이미지
                      : "/images/main2/07 인트로/07 인트로 10.png" // 기본 이미지
                  }
                  alt="요금제 비교"
                  sx={{
                    maxWidth: "60%",
                    objectFit: "contain",
                    transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
};

export default FunctionPage;
