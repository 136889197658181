import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
  }

  // 페이지 로드 시 최상단으로 이동
  window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "auto", // 이 옵션이 스크롤을 즉시 이동시킵니다.
    // });
  }, [pathname]);

  return null; // UI를 렌더링하지 않는 컴포넌트
};

export default ScrollToTop;
