import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '##003366', // 파란색 계열의 특정 색상
  //     light: '#42a5f5', // 보다 밝은 파란색
  //     dark: '#1565c0', // 보다 어두운 파란색
  //     contrastText: '#fff', // 대비되는 텍스트 색상 (여기서는 흰색)
  //   },
  //   // 다른 색상 설정들...
  // },

  // typography: {
  //   fontFamily: 'NanumSquareR, Arial, sans-serif',
  // },
});
export default theme;