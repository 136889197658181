import React, { useState, useEffect, FormEvent } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  Link
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RootState } from "@/index";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { fetchNoticeBoard, updateNoticeBoard } from "../../redux/noticeBoard/noticeBoardActions";
import { NoticeBoardRegisterType } from "../../types/noticeBoardTypes";
import { useTheme } from "@mui/material/styles";

const NoticeBoardUpdateForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(470));
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [existingFileUrl, setExistingFileUrl] = useState<string>("");
  const [noticeBoard, setNoticeBoard] = useState<NoticeBoardRegisterType>({
    author: "",
    title: "",
    inquiryDetail: ""
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchNoticeBoard(id))
        .then(data => {
          setNoticeBoard({
            author: data.author,
            title: data.title,
            inquiryDetail: data.inquiryDetail
          });
          setExistingFileUrl(data.fileUrl);
        });
    }
  }, [id, dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files ? e.target.files[0] : null;
      setImageFile(file);
    } else {
      setNoticeBoard(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (id) {
      dispatch(updateNoticeBoard(id, noticeBoard, imageFile, navigate));
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ minWidth: "320px", mt: 5 }}>
      <Typography variant="h6" gutterBottom sx={{fontWeight:"bold", mb:5}}>
        공지사항 수정
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={isSmallScreen ? 12 : 10}>
          <TextField
            required
            fullWidth
            label="제목"
            name="title"
            value={noticeBoard.title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 2}>
          <TextField
            required
            fullWidth
            label="작성자"
            name="author"
            value={noticeBoard.author}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            rows={7}
            label="내용"
            name="inquiryDetail"
            value={noticeBoard.inquiryDetail}
            onChange={handleChange}
          />
        </Grid>
        {existingFileUrl && (
          <Grid item xs={12}>
            <Typography>현재 파일: <Link href={existingFileUrl} target="_blank">{existingFileUrl.split('/').pop()}</Link></Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField type="file" fullWidth onChange={handleChange} />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="contained" type="submit" sx={{ mr: 3 }}>
            수정
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            취소
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoticeBoardUpdateForm;
