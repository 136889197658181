import { RootState } from "@/index";
import mainRequest from "../../api/mainRequest";
import { Box, CardMedia, Container, Typography } from "@mui/material";
import React, { RefObject, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InstallPage = () => {
  const Manager = useSelector((state: RootState) => state.manager);
  const navigate = useNavigate();
  const sectionRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const fileUrl = process.env.REACT_APP_FILE_DOWNLOAD_URL; // 환경 변수에서 URL 가져오기

  // 상태를 사용하여 이미지 변경

  const [hovered1, setHovered1] = React.useState(false);
  const [hovered2, setHovered2] = React.useState(false);
  const [hovered3, setHovered3] = React.useState(false);

  // 배열을 생성하여 각 요소에 대해 `createRef`를 호출
  for (let i = 0; i < 6; i++) {
    sectionRefs.current[i] = React.createRef();
  }


  const handleDownload = async () => {
    if (!Manager.isLoggedIn){
      alert(" 회원만 가능합니다.");
      return;

    }
    try {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      };
      const response = await mainRequest.get(`/install/download`, {
        ...config,
        responseType: "blob", // 파일 데이터로 응답받기
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LogVisor_SetUp.zip"); // 저장할 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("파일 다운로드 중 오류 발생:", error);
      alert("파일 다운로드에 실패했습니다.");
    }
  };
  return (
    <>
      <Container
        sx={{
          mt: 20,
          mb: 20,
          "@media (max-width: 800px)": {
            mt: 10,
            mb: 10,
          },
        }}
      ></Container>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* 고객 지원 > 제품설치 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          <Box
            sx={{
              // maxWidth: "70%",
              width: "50%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/01 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/01 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "35%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "45%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "50%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "50%",
                },
              }}
            />
          </Box>

          <CardMedia
            component="img"
            image="/images/제품설치/01 제품설치/합본.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "40%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: 5,
              width: "10%", // 컨테이너 너비
              height: "70px", // 이미지 높이 설정
              // maxWidth: 300, // 이미지 최대 크기
              borderRadius: 10,
              backgroundImage: `url(${
                hovered1
                  ? encodeURI("/images/제품설치/01 제품설치/15색변경.png") // 마우스 오버 시 이미지
                  : encodeURI("/images/제품설치/01 제품설치/15.png") // 기본 이미지
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
              "@media (max-width: 1500px)": {
                width: "20%",
                height: "70px", // 이미지 높이 설정
              },
              "@media (max-width: 1000px)": {
                width: "30%",
                height: "60px", // 이미지 높이 설정
              },
              "@media (max-width: 800px)": {
                width: "40%",
                height: "50px", // 이미지 높이 설정
              },
            }}
            onClick={() =>
              sectionRefs.current[0].current?.scrollIntoView({
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setHovered1(true)} // 마우스가 올라갈 때
            onMouseLeave={() => setHovered1(false)} // 마우스가 벗어날 때
          >
            <CardMedia
              component="img"
              image="/images/제품설치/01 제품설치/16.png"
              alt="image-logvisor"
              sx={{
                position: "absolute",
                zIndex: 2,
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "70%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "60%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "60%",
                },
              }}
            />
          </Box>
        </Box>

        {/* 고객 지원 제품설치 회원가입 및 결제 */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/02 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/02 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "75%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "90%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "90%",
                },
              }}
            />
          </Box>

          <Box
            className="container"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 5,
              "@media (max-width: 800px)": {
                flexDirection: "column", // 화면 너비가 600px 이하일 때 세로로 정렬
                alignItems: "center",
              },
              mb: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                image="/images/제품설치/02 제품설치/03.png"
                alt="1.회원가입을 합니다."
                sx={{
                  mb: 6,
                  // maxWidth: 500,
                  maxWidth: "30%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "40%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "50%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "60%",
                  },
                }}
              />

              <CardMedia
                component="img"
                image="/images/제품설치/02 제품설치/04.png"
                alt="image-logvisor"
                sx={{
                  // maxWidth: 500,
                  maxWidth: "60%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "70%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "80%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "90%",
                  },
                }}
              />
            </Box>
            <CardMedia
              component="img"
              image="/images/제품설치/02 제품설치/05.png"
              alt="2.관리자는 로그인후"
              sx={{
                mb: 3,
                // maxWidth: 500,
                maxWidth: "2%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {},
                "@media (max-width: 1000px)": {},
                "@media (max-width: 800px)": {},
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                image="/images/제품설치/02 제품설치/06.png"
                alt="2.관리자는 로그인후"
                sx={{
                  mb: 3,
                  // maxWidth: 500,
                  maxWidth: "60%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "70%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "80%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "90%",
                  },
                }}
              />
              <CardMedia
                component="img"
                image="/images/제품설치/02 제품설치/07.png"
                alt="image-logvisor"
                sx={{
                  // maxWidth: 500,
                  maxWidth: "60%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "70%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "80%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "90%",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        {/* 완료 */}

        {/* agent 설치 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              // maxWidth: "70%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/03 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/03 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "20%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "30%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "30%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "30%",
                },
              }}
            />
          </Box>
          <Box
            className="container"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              mb: 2,
              "@media (max-width: 1000px)": {
                // flexDirection: "column", // 화면 너비가  이하일 때 세로로 정렬
                alignItems: "center",
                mb: 2,
              },
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "23px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoEB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#002c6a",
                lineHeight: "1.5",
                // marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "20px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "10px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              3.LogVisor 설치 전에 MS OTP를 스마트폰에 설치 합니다.<br></br>
              이미 설치가 되었다면 Agent 설치 단계로 넘어 갑니다.
            </Typography>

            <CardMedia
              component="img"
              image="/images/제품설치/03 제품설치/04.png"
              alt="image-logvisor"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "15%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  // maxWidth: "70%",
                },
                "@media (max-width: 1000px)": {
                  // maxWidth: "80%",
                },
                "@media (max-width: 800px)": {
                  // maxWidth: "90%",
                },
              }}
            />
            <Box
              sx={{
                position: "relative",
                cursor: "pointer",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // mb: 5,
                width: "18%", // 컨테이너 너비
                height: "70px", // 이미지 높이 설정
                // maxWidth: 300, // 이미지 최대 크기
                borderRadius: 10,
                backgroundImage: `url(${
                  hovered2
                    ? encodeURI("/images/제품설치/01 제품설치/15색변경.png") // 마우스 오버 시 이미지
                    : encodeURI("/images/제품설치/01 제품설치/15.png") // 기본 이미지
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
                "@media (max-width: 1500px)": {
                  width: "20%",
                  height: "70px", // 이미지 높이 설정
                },
                "@media (max-width: 1000px)": {
                  width: "30%",
                  height: "60px", // 이미지 높이 설정
                },
                "@media (max-width: 800px)": {
                  width: "40%",
                  height: "50px",
                },
              }}
              onClick={() =>
                sectionRefs.current[0].current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
              onMouseEnter={() => setHovered2(true)} // 마우스가 올라갈 때
              onMouseLeave={() => setHovered2(false)} // 마우스가 벗어날 때
            >
              <CardMedia
                component="img"
                image="/images/제품설치/01 제품설치/16.png"
                alt="image-logvisor"
                sx={{
                  position: "absolute",
                  zIndex: 2,
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "75%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "60%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "70%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "70%",
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            className="container"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              // alignItems: "center",
              gap: 5,
              mb: 5,
              "@media (max-width: 1000px)": {
                // flexDirection: "column", // 화면 너비가  이하일 때 세로로 정렬
                alignItems: "center",
                mb: 5,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "start",
                gap: 5,
                mb: 5,
                "@media (max-width: 1000px)": {
                  mb: 5,
                },
              }}
            >
              <CardMedia
                component="img"
                image="/images/제품설치/03 제품설치/합본1.png"
                alt="image-logvisor"
                sx={{
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "70%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "80%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "90%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "100%",
                  },
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // mb: 5,
                  width: "30%", // 컨테이너 너비
                  height: "70px", // 이미지 높이 설정
                  // maxWidth: 300, // 이미지 최대 크기
                  borderRadius: 10,
                  backgroundImage: `url(${
                    hovered1
                      ? encodeURI("/images/제품설치/03 제품설치/05색변경.png") // 마우스 오버 시 이미지
                      : encodeURI("/images/제품설치/03 제품설치/05.png") // 기본 이미지
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
                  "@media (max-width: 1500px)": {
                    width: "20%",
                    height: "70px", // 이미지 높이 설정
                  },
                  "@media (max-width: 1000px)": {
                    width: "30%",
                    height: "50px", // 이미지 높이 설정
                  },
                  "@media (max-width: 800px)": {
                    width: "40%",
                    height: "50px",
                  },
                }}
                onClick={handleDownload}
                onMouseEnter={() => setHovered1(true)} // 마우스가 올라갈 때
                onMouseLeave={() => setHovered1(false)} // 마우스가 벗어날 때
              >
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: "25px", // 41pt에 해당하는 px 크기
                    fontFamily: "'NanumSquareB'", // 나눔스퀘어네오H 폰트 지정
                    fontWeight: "900",
                    color: "white",
                    lineHeight: "1.5",
                    "@media (max-width: 1500px)": {
                      fontSize: "20px", // 작은 화면에서는 적절히 조정
                    },
                    "@media (max-width: 1000px)": {
                      fontSize: "15px", // 작은 화면에서는 적절히 조정
                    },
                    "@media (max-width: 800px)": {
                      fontSize: "14px", // 작은 화면에서는 적절히 조정
                    },
                  }}
                >
                  다운로드
                </Typography>
              </Box>
            </Box>

            <CardMedia
              component="img"
              image="/images/제품설치/03 제품설치/09점선.png"
              alt="image-logvisor"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "0.1%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {},
                "@media (max-width: 1000px)": {},
                "@media (max-width: 800px)": {},
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                gap: 5,
                mb: 5,
                "@media (max-width: 1000px)": {
                  mb: 5,
                },
              }}
            >
              <CardMedia
                component="img"
                image="/images/제품설치/03 제품설치/합본2.png"
                alt="image-logvisor"
                sx={{
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "65%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 1500px)": {
                    maxWidth: "75%",
                  },
                  "@media (max-width: 1000px)": {
                    maxWidth: "85%",
                  },
                  "@media (max-width: 800px)": {
                    maxWidth: "95%",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        {/* agent 설치완료 */}

        {/* agent 등록  */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 30,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              // maxWidth: "70%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/04 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/04 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                width: "20%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  width: "30%",
                },
                "@media (max-width: 1000px)": {
                  width: "30%",
                },
                "@media (max-width: 800px)": {
                  width: "30%",
                },
              }}
            />
          </Box>
          <Box>
            <CardMedia
              component="img"
              image="/images/제품설치/04 제품설치/합본1.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "60%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                },
              }}
            />
          </Box>
          <Box
            className="container"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 0,
              // mb: 5,
              "@media (max-width: 1000px)": {
                // flexDirection: "column", // 화면 너비가  이하일 때 세로로 정렬
                // alignItems: "center",
                // mb: 5,
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/04 제품설치/06.png"
              alt="image-logvisor"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                margin: 0, // 텍스트 주위 여백 제거
                padding: 0, // 텍스트 내부 여백 제거
                "@media (max-width: 1500px)": {
                  // maxWidth: "70%",
                },
                "@media (max-width: 1000px)": {
                  // maxWidth: "80%",
                },
                "@media (max-width: 800px)": {
                  // maxWidth: "90%",
                },
              }}
            />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "25px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoEB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                margin: 0, // 텍스트 주위 여백 제거
                padding: 0, // 텍스트 내부 여백 제거
                "@media (max-width: 1500px)": {
                  fontSize: "20px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "10px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              Icon을 클릭합니다. 최초 1회 본인 인증을 진행합니다.
            </Typography>
          </Box>
          <CardMedia
            component="img"
            image="/images/제품설치/04 제품설치/합본2.png"
            alt="image-logvisor"
            sx={{
              // maxWidth: 500,
              maxWidth: "60%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />

          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // mb: 5,
              width: "15%", // 컨테이너 너비
              height: "70px", // 이미지 높이 설정
              // maxWidth: 300, // 이미지 최대 크기
              borderRadius: 10,
              backgroundImage: `url(${
                hovered1
                  ? encodeURI("/images/제품설치/04 제품설치/17색변경.png") // 마우스 오버 시 이미지
                  : encodeURI("/images/제품설치/04 제품설치/17.png") // 기본 이미지
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
              "@media (max-width: 1500px)": {
                width: "20%",
                height: "70px", // 이미지 높이 설정
              },
              "@media (max-width: 1000px)": {
                width: "30%",
                height: "60px", // 이미지 높이 설정
              },
              "@media (max-width: 800px)": {
                width: "40%",
                height: "50px",
              },
            }}
            onClick={() =>
              sectionRefs.current[0].current?.scrollIntoView({
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setHovered1(true)} // 마우스가 올라갈 때
            onMouseLeave={() => setHovered1(false)} // 마우스가 벗어날 때
          >
            <CardMedia
              component="img"
              image="/images/제품설치/04 제품설치/18.png"
              alt="image-logvisor"
              sx={{
                position: "absolute",
                zIndex: 2,
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "70%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "60%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "70%",
                },
              }}
            />
          </Box>
        </Box>

        {/* agent 등록완료 */}

        {/* agent 실행 시작 */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 10,
            "@media (max-width: 1000px)": {
              mb: 10,
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              // maxWidth: "70%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/05 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/05 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "20%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "30%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "30%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "30%",
                },
              }}
            />
          </Box>
          <Box>
            <CardMedia
              component="img"
              image="/images/제품설치/05 제품설치/합본.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "80%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "90%",
                },
              }}
            />
          </Box>
        </Box>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 0,
            mb: 10,
            "@media (max-width: 800px)": {
              mb: 5,
            },
          }}
        >
          <Box
            // 페이드인예정1
            textAlign="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "40px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoEB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#002c6a",
                lineHeight: "1.5",
                // mt: 5,
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              2단계(2Factor) 인증으로 더욱 안전하게
            </Typography>
          </Box>

          {/* 1단계 */}
          <Box
            textAlign="center"
            // 페이드인예정2
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 03.png"
              alt="1단계"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                },
              }}
            />
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 04.png"
              alt="2팩터 PC로그인 3단계 변화"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 05.png"
              alt="모니터별 설명1"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />

            {/* 아래화살표 */}
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 06.png"
              alt="down-arrow"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "10%",
                  marginBottom: 5,
                },
              }}
            />
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "21px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "500",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              로그인 후 이력이 관리자 페이지에 누적
            </Typography>
          </Box>
          {/* 2단계 */}
          <Box
            textAlign="center"
            // 페이드인예정3
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 08.png"
              alt="2단계"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 09.png"
              alt="opt입력 3단계"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                // marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  // marginBottom: 5,
                },
              }}
            />

            <Box
              className="container"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "20vw",
                "@media (max-width: 520px)": {
                  gap: "30vw",
                },
                mb: 5,
              }}
            >
              <Box></Box>

              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontSize: "18px", // 41pt에 해당하는 px 크기
                  fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                  fontWeight: "500",
                  color: "#3f3a39",
                  lineHeight: "1.5",
                  transform: "translate(80px, 0px)",
                  "@media (max-width: 1000px)": {
                    fontSize: "14px", // 작은 화면에서는 적절히 조정
                    transform: "translate(50px, 0px)",
                  },
                  "@media (max-width: 800px)": {
                    fontSize: "10px", // 작은 화면에서는 적절히 조정
                    transform: "translate(40px, 0px)",
                  },
                }}
              >
                MS OTP 6자리 입력
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  fontSize: "18px", // 41pt에 해당하는 px 크기
                  fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                  fontWeight: "500",
                  color: "#3f3a39",
                  lineHeight: "1.5",
                  transform: "translate(-90px, 0px)",
                  "@media (max-width: 1000px)": {
                    fontSize: "14px", // 작은 화면에서는 적절히 조정
                    transform: "translate(-50px, 0px)",
                  },
                  "@media (max-width: 800px)": {
                    fontSize: "10px", // 작은 화면에서는 적절히 조정
                    transform: "translate(0px, 0px)",
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "10px", // 작은 화면에서는 적절히 조정
                    transform: "translate(-50px, 0px)",
                  },
                }}
              >
                정상 로그인
              </Typography>
            </Box>

            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 11.png"
              alt="down-arrow"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "10%",
                  marginBottom: 5,
                },
              }}
            />
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "21px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "500",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              로그인 후 이력이 관리자 페이지에 누적
            </Typography>
          </Box>
          <Box
            textAlign="center"
            // 페이드인예정4
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/*ms windows hello 적용시 생체인증 로그인 가능 */}
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 13.png"
              alt="image-logvisor"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "80%",
                  marginBottom: 5,
                },
              }}
            />

            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontSize: "28px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoEB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  fontSize: "20px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              [다양한 생체정보 Device]
            </Typography>
            <Box
              className="container"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 5,
                "@media (max-width: 800px)": {
                  flexDirection: "column", // 화면 너비가 600px 이하일 때 세로로 정렬
                  alignItems: "center",
                },
                mb: 10,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative", // 자식 요소들의 위치를 겹치게 설정하기 위한 relative
                  maxWidth: "100%", // 부모 박스의 크기 설정
                  height: "auto", // 이미지 높이를 자율적으로 설정
                }}
              >
                <CardMedia
                  component="img"
                  image="/images/main2/06 인트로/06 인트로 14.png"
                  alt="장비들"
                  sx={{
                    // maxHeight: 500,
                    // maxWidth: 500,
                    // maxWidth: "100%",
                    maxWidth: "60%",
                    height: "auto",
                    objectFit: "cover",
                    "@media (max-width: 800px)": {
                      marginBottom: 5,
                    },
                  }}
                />
                <CardMedia
                  component="img"
                  image="/images/main2/06 인트로/06 인트로 15.png"
                  alt="장비설명"
                  sx={{
                    position: "absolute",
                    top: "70%", // 상단에서 50% 내려오게 조정 (필요에 따라 변경 가능)
                    left: "40%", // 좌측에서 50% 이동 (필요에 따라 변경 가능)
                    transform: "translate(-35%, -50%)", // 중앙에 배치
                    maxWidth: "60%", // 크기 조정 (필요에 따라 변경)
                    height: "auto", // 이미지 비율 유지
                    // maxHeight: 500,
                    // maxWidth: 500,
                    // maxWidth: "100%",
                    // height: "auto",
                    objectFit: "contain",
                    "@media (max-width: 1000px)": {
                      transform: "translate(-35%, -50%)", // 중앙에 배치
                    },
                    "@media (max-width: 800px)": {
                      transform: "translate(-35%, -50%)", // 800px 이하일 때
                    },
                  }}
                />
              </Box>

              <CardMedia
                component="img"
                image="/images/main2/06 인트로/06 인트로 16.png"
                alt="right-arrow"
                sx={{
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "1%",
                  // height: "auto",
                  transform: "translate(-500%, 0%)",
                  objectFit: "contain",
                  "@media (max-width: 800px)": {
                    transform: "translate(0%, 0%)",
                    maxWidth: "5%",
                    marginBottom: 5,
                  },
                }}
              />
              <CardMedia
                component="img"
                image="/images/main2/06 인트로/06 인트로 17.png"
                alt="로그인 모니터"
                sx={{
                  // maxHeight: 500,
                  // maxWidth: 500,
                  maxWidth: "25%",
                  // height: "auto",
                  objectFit: "contain",
                  "@media (max-width: 800px)": {
                    marginBottom: 5,
                    maxWidth: "60%",
                  },
                }}
              />
            </Box>
            <CardMedia
              component="img"
              image="/images/main2/06 인트로/06 인트로 18.png"
              alt="down- arrow"
              sx={{
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "5%",
                // height: "auto",
                objectFit: "contain",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  maxWidth: "10%",
                  // marginBottom: 5,
                },
              }}
            />
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "21px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareR'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "500",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 0,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              로그인 후 이력이 관리자 페이지에 누적
            </Typography>
          </Box>
        </Box>

        {/* agent실행완료 */}
        {/*제품 설치 시작 */}

        <Box
          ref={sectionRefs.current[0]} // 이제 여기에서 ref를 할당
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mt: 15,
            mb: 15,
            "@media (max-width: 1000px)": {
              mb: 10,
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              // maxWidth: "70%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/06 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/06 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "15%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "23%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "23%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "23%",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: 5,
              width: "20%", // 컨테이너 너비
              height: "90px", // 이미지 높이 설정
              // maxWidth: 300, // 이미지 최대 크기
              borderRadius: 10,
              backgroundImage: `url(${
                hovered1
                  ? encodeURI("/images/제품설치/01 제품설치/15색변경.png") // 마우스 오버 시 이미지
                  : encodeURI("/images/제품설치/01 제품설치/15.png") // 기본 이미지
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              transition: "all 0.3s ease-in-out", // 부드러운 전환 효과
              "@media (max-width: 1500px)": {
                width: "25%",
                height: "80px", // 이미지 높이 설정
              },
              "@media (max-width: 1000px)": {
                width: "30%",
                height: "70px", // 이미지 높이 설정
              },
              "@media (max-width: 800px)": {
                width: "40%",
                height: "60px", // 이미지 높이 설정
              },
            }}
            onClick={() =>
              sectionRefs.current[0].current?.scrollIntoView({
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setHovered1(true)} // 마우스가 올라갈 때
            onMouseLeave={() => setHovered1(false)} // 마우스가 벗어날 때
          >
            <CardMedia
              component="img"
              image="/images/제품설치/01 제품설치/16.png"
              alt="image-logvisor"
              sx={{
                position: "absolute",
                zIndex: 2,
                // maxHeight: 500,
                // maxWidth: 500,
                maxWidth: "70%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "70%",
                },
              }}
            />
          </Box>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: "35px", // 41pt에 해당하는 px 크기
              fontFamily: "'NanumSquareNeoEB'", // 나눔스퀘어네오H 폰트 지정
              fontWeight: "900",
              color: "#002c6a",
              lineHeight: "1.5",
              marginBottom: 5,
              "@media (max-width: 800px)": {
                fontSize: "25px", // 작은 화면에서는 적절히 조정
              },
            }}
          >
            MS OTP를 설치하세요
          </Typography>
          <CardMedia
            component="img"
            image="/images/제품설치/06 제품설치/합본.png"
            alt="image-logvisor"
            sx={{
              // maxWidth: 500,
              maxWidth: "50%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>

        {/* 제품설치완료 */}

        {/* QR CodeScan */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              // maxWidth: "70%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/07 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/07 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "25%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "35%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "35%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "35%",
                },
              }}
            />
          </Box>
          <CardMedia
            component="img"
            image="/images/제품설치/07 제품설치/합본.png"
            alt="image-logvisor"
            sx={{
              // maxWidth: 500,
              maxWidth: "60%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>
        {/* QR코드스캔완료 */}

        {/* 계정이름변경 시작 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          <Box
            sx={{
              width: "60%",
              // maxWidth: "70%",
              mb: 5,
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품설치/08 제품설치/01.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                width: "70%",
              },
              "@media (max-width: 1000px)": {
                width: "80%",
              },
              "@media (max-width: 800px)": {
                width: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품설치/08 제품설치/02.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "20%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "30%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "30%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "30%",
                },
              }}
            />
          </Box>
          <CardMedia
            component="img"
            image="/images/제품설치/08 제품설치/합본.png"
            alt="image-logvisor"
            sx={{
              // maxWidth: 500,
              maxWidth: "60%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default InstallPage;
