import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Check } from "@mui/icons-material";
interface TableCellWithOverflowProps {
  children: React.ReactNode;
  widthProp: string;
  isHeader?: boolean;
  isColumnOne?: boolean;
  isFeatureRow?: boolean;
}

const TableCellWithOverflow: React.FC<TableCellWithOverflowProps> = ({
  children,
  widthProp,
  isHeader = false, // 기본값은 false
  isColumnOne = false,
  isFeatureRow = false,
}) => (
  <TableCell
    sx={{
      maxWidth: "650px",
      width: widthProp,
      // textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      border: "1px dashed #ccc",
      textAlign: "center",
      // padding:"15px"
      fontFamily: "'Nanum Gothic'", // isHeader가 true일 때만 글꼴 적용
      fontSize: isFeatureRow ? "20px" : isHeader ? "20px" : "16px", // isHeader가 true일 때만 글자 크기 적용
      fontWeight: isHeader || isColumnOne ? "bold" : undefined, // Modify this line// isHeader가 true일 때만 글자 굵기 적용
      color: isHeader || isFeatureRow ? "#0057af" : "inherit",
      backgroundColor: isFeatureRow ? "aliceblue" : "inherit", // aliceblue
    }}
  >
    {children}
  </TableCell>
);

type PricingTier = {
  minUsers: number;
  maxUsers: number;
  pricePerUser: number;
};

const pricingTiers: PricingTier[] = [
  { minUsers: 1, maxUsers: 10, pricePerUser: 6000 },
  { minUsers: 11, maxUsers: 30, pricePerUser: 5000 },
  { minUsers: 31, maxUsers: 50, pricePerUser: 4000 },
  { minUsers: 51, maxUsers: 100, pricePerUser: 3000 },
];

const RealPaymentPage: React.FC = () => {
  const navigate = useNavigate();
  const [planType, setPlanType] = useState("monthly");

  console.log("RealpaymentPage 렌더링");

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanType((event.target as HTMLInputElement).value);
  };

  const calculatePrice = (price: number) =>
    planType === "annual" ? price * 0.8 : price;

  const handlePurchase = (tier: PricingTier) => {
    const calculatedPrice =
      planType === "annual" ? tier.pricePerUser * 12 * 0.8 : tier.pricePerUser;
    navigate("/credit-card", {
      state: {
        productType: `${tier.minUsers} ~ ${tier.maxUsers} Users`,
        productPrice: calculatedPrice,
        planType,
      },
    });
  };

  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginTop: 5,
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: "bold",
                  "@media (max-width: 700px)": {
                    fontSize: "13px", //
                  },
                }}
              >
                임대형 요금제를 선택해 보세요
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "grey", fontWeight: "bold", marginTop: 2 }}
              >
                인원수에 따라 요금이 다릅니다.
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                결제 플랜을 선택해주세요!
              </FormLabel>
              <RadioGroup
                row
                aria-label="payment plan"
                name="paymentPlan"
                value={planType}
                onChange={handlePlanChange}
              >
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="월간 플랜"
                />
                <FormControlLabel
                  value="annual"
                  control={<Radio />}
                  label="연간 플랜 (20% 할인)"
                />
              </RadioGroup>
            </FormControl>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: 3 }}
              justifyContent="center"
            >
              {pricingTiers.map((tier, index) => (
                <Grid item key={index}>
                  <Card
                    sx={{
                      minWidth: "200px",
                      // width: "285px",
                      borderRadius: 4,
                    }}
                  >
                    <CardContent
                      sx={{
                        minHeight: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ fontWeight: "bold" }}
                        >
                          {tier.minUsers} ~ {tier.maxUsers} Users
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", mr: 1 }}
                          >
                            {calculatePrice(tier.pricePerUser).toLocaleString()}{" "}
                            원
                          </Typography>
                          <Typography variant="body2">/ 월별, 1인당</Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={
                          {
                            // display: "flex",
                            // justifyContent: "end",
                            // marginTop: 4,
                          }
                        }
                      >
                        <Button
                          variant="contained"
                          // variant="outlined"
                          onClick={() => handlePurchase(tier)}
                          sx={{
                            width: 150,
                            height: 40,
                            fontWeight: "bold",
                            borderRadius: "20px",
                            fontSize: 18,
                            fontFamily: "'Nanum Gothic'",
                            color: "white",
                            backgroundColor: "#0057af", // Default background color
                            "&:hover": {
                              backgroundColor: "#1976d2", // Background color on hover
                              color: "white",
                            },
                          }}
                        >
                          지금 구매
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

              <Grid item>
                <Card
                  sx={{
                    minWidth: "200px",
                    // width: "285px",
                    borderRadius: 4,
                  }}
                >
                  <CardContent
                    sx={{
                      minHeight: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        100+ Users
                      </Typography>
                      <Typography variant="h6">별도 문의</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 3,
                        }}
                      ></Box>

                      <Box
                        sx={{
                          // display: "flex",
                          // justifyContent: "end",
                          marginTop: 7,
                        }}
                      >
                        <Button
                          variant="contained"
                          // variant="outlined"
                          onClick={() => navigate("/payment-board-register")}
                          sx={{
                            width: 150,
                            height: 40,
                            fontWeight: "bold",
                            borderRadius: "20px",
                            fontSize: 18,
                            fontFamily: "'Nanum Gothic'",
                            color: "white",
                            backgroundColor: "#0057af", // Default background color
                            "&:hover": {
                              backgroundColor: "#1976d2", // Background color on hover
                              color: "white",
                            },
                          }}
                        >
                          도입 문의
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: "right", my: 5 }}
          >
            {planType === "annual" ? (
              <>
                * 위 금액은 1인 기준 연간 사용 요금이며, VAT(부가세) 포함입니다.
                <br />
                최종 가격은 12개월 연간 사용료*수량으로 자동 계산되어
                청구됩니다.
              </>
            ) : (
              <>
                * 위 금액은 1인 기준 월간 사용 요금이며, VAT(부가세) 포함입니다.
                <br />
                최종 가격은 월간 사용료*수량으로 자동 계산되어 청구됩니다.
              </>
            )}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default RealPaymentPage;
