import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  Container,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  createBillingKey,
  preparePayment,
  verifyPayment,
} from "../../redux/order/OrderActions";
import { v4 as uuidv4 } from "uuid"; // UUID 라이브러리를 설치하고 가져오기

interface CardDetails {
  productType: string;
  productPrice: string;
  planType: string;
  cardType: string;
  birthDay?: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  pw2digit: string;
  cardholderName: string;
  cardholderTel: string;
  cardholderAddress: string;
  email: string;
}

declare global {
  interface Window {
    IMP: any;
  }
}
const CreditCardPage: React.FC = () => {
  const location = useLocation();

  const { productType, productPrice, planType } = location.state || {};
  const [customerUid] = useState(uuidv4()); // UUID로 고객 고유 UID 생성
  const [cardDetails, setCardDetails] = useState<CardDetails>({
    productType,
    productPrice,
    planType,
    cardType: "personal",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    pw2digit: "",
    cardholderName: "",
    cardholderTel: "",
    cardholderAddress: "",
    email: "",
    birthDay: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const IMP = window.IMP;
  const IMP_KEY = process.env.REACT_APP_IMP_KEY;

  useEffect(() => {
    console.log("IMP_KEY: ", IMP_KEY);
    IMP.init(IMP_KEY);
  }, [IMP]);

  const handleDanalPay = async () => {
    const today = new Date();
    const year = today.getFullYear(); // 년도
    const month = today.getMonth() + 1; // 월 (0부터 시작하므로 1을 더해야 함)
    const day = today.getDate(); // 일

    // 월과 일이 한 자리 수일 경우 앞에 '0'을 추가
    const monthStr = month < 10 ? `0${month}` : `${month}`;
    const dayStr = day < 10 ? `0${day}` : `${day}`;

    const dateNumber = `${year}${monthStr}${dayStr}`;

    var hours = today.getHours(); // 시
    var minutes = today.getMinutes(); // 분
    var seconds = today.getSeconds(); // 초
    var milliseconds = today.getMilliseconds();

    var makeMerchantUid =
      dateNumber +
      "_" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      ":" +
      milliseconds;

    const nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1); // 한 달 후

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}${month}${day}`;
    };

    const periodFrom = formatDate(today);
    const periodTo = formatDate(nextMonth);
    // 총 결제 금액 계산
    const totalAmount =
      (Number(numberOfApplicants) || 1) * Number(cardDetails.productPrice);
    const prepareReq = {
      merchantUid: `uid_${makeMerchantUid}`,
      amount: totalAmount, //사전 결제검증 금액
    };

    // preparePayment(prepareReq)
    // createSubscriptionPayment(cardDetails);

    IMP.request_pay(
      {
        channelKey: "channel-key-29f446a8-43f1-428d-9495-4060385fcaae",
        pay_method: "card",
        merchant_uid: "uid_" + makeMerchantUid, //주문번호
        name: productType,
        amount: totalAmount, // 총 가격
        customer_uid: customerUid,
        buyer_email: cardDetails.email,
        buyer_name: cardDetails.cardholderName,
        buyer_tel: cardDetails.cardholderTel,
        buyer_addr: cardDetails.cardholderAddress,
        period: {
          from: periodFrom, //YYYYMMDD
          to: periodTo, //YYYYMMDD
        },
      },
      function (rsp: any) {
        const { status, err_msg } = rsp;
        console.log("rsp:", rsp);
        if (rsp.success) {
          // verifyPayment(rsp);
          const managerBillingInfo = {
            customerUid: rsp.customer_uid,
          };
          createBillingKey(managerBillingInfo);
          alert("빌링키 발급성공");
        } else {
          alert(err_msg + "빌링키 발급 실패");
        }
      }
    );
  };

  // car_number: cardDetails.cardNumber,
  // expiry: "19"+cardDetails.expiryYear+"-"+cardDetails.expiryMonth,
  // birth: cardDetails.cardType === "corporate" ? cardDetails.businessNumber : cardDetails.birthDay,
  // pwd_2digit: cardDetails.pw2digit,
  // car_number: "4907-3100-4433-3325",
  // expiry: "2029-03",
  // birth: "901215",
  // pwd_2digit: "85",

  // 상태에서 정보 추출

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCardDetails({
      ...cardDetails,
      [name]: value,
    });
  };

  const handleCardNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const value = event.target.value;
    const numbers = cardDetails.cardNumber.split(" ");
    numbers[index] = value;
    setCardDetails({
      ...cardDetails,
      cardNumber: numbers.join(" "),
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateEmail(cardDetails.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!termsAccepted) {
      alert("Please accept the terms and conditions to proceed.");
      return;
    }
    handleDanalPay();
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };

  // 신청 인원수를 위한 상태 추가
  const [numberOfApplicants, setNumberOfApplicants] = useState<string>("1");

  const handleApplicantChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      // 숫자만 입력되도록 제한
      setNumberOfApplicants(value);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
              결제 정보 입력
            </Typography>

            <form onSubmit={handleSubmit}>
              {/* <FormControl component="fieldset" sx={{ mt: 2 }}>
                <FormLabel component="legend">카드 유형</FormLabel>
                <RadioGroup
                  row
                  aria-label="card type"
                  name="cardType"
                  value={cardDetails.cardType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="corporate"
                    control={<Radio />}
                    label="법인카드"
                  />
                  <FormControlLabel
                    value="personal"
                    control={<Radio />}
                    label="개인카드"
                  />
                </RadioGroup>
              </FormControl>
              {cardDetails.cardType === "corporate" && (
                <>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    사업자 등록번호 (10자리)
                  </Typography>
                  <TextField
                    margin="normal"
                    label="-빼고 입력"
                    fullWidth
                    name="businessNumber"
                    value={cardDetails.birthDay}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                  />
                </>
              )}
              {cardDetails.cardType === "personal" && (
                <>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    생년월일 (6자리)
                  </Typography>
                  <TextField
                    margin="normal"
                    label="YYMMDD"
                    fullWidth
                    name="birthDay"
                    value={cardDetails.birthDay}
                    onChange={handleChange}
                    inputProps={{ maxLength: 6, pattern: "[0-9]*" }}
                  />
                </>
              )}
              <Typography variant="body2" sx={{ mt: 2 }}>
                카드 번호
              </Typography>
              <Grid container spacing={1}>
                {Array.from({ length: 4 }).map((_, index) => (
                  <Grid item xs={3} key={index}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name={`cardNumber${index + 1}`}
                      value={cardDetails.cardNumber.split(" ")[index] || ""}
                      onChange={(event) => handleCardNumberChange(event, index)}
                      inputProps={{ maxLength: 4, pattern: "[0-9]*" }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Typography variant="body2" sx={{ mt: 2 }}>
                유효 기간
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="MM"
                    name="expiryMonth"
                    value={cardDetails.expiryMonth}
                    onChange={handleChange}
                    inputProps={{ maxLength: 2, pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="YY"
                    name="expiryYear"
                    value={cardDetails.expiryYear}
                    onChange={handleChange}
                    inputProps={{ maxLength: 2, pattern: "[0-9]*" }}
                  />
                </Grid>
              </Grid>
              <Typography variant="body2" sx={{ mt: 2 }}>
                카드 비밀번호 앞 두 자리
              </Typography>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    name="pw2digit"
                    value={cardDetails.pw2digit}
                    onChange={handleChange}
                    inputProps={{ maxLength: 2, pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    ●●
                  </Typography>
                </Grid>
              </Grid> */}
              <Typography variant="body2" sx={{ mt: 2 }}>
                회사 주소
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="cardholderAddress"
                value={cardDetails.cardholderAddress}
                onChange={handleChange}
              />
              <Typography variant="body2" sx={{ mt: 2 }}>
                정산 담당자 전화번호
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="cardholderTel"
                value={cardDetails.cardholderTel}
                onChange={handleChange}
              />
              <Typography variant="body2" sx={{ mt: 2 }}>
                정산 담당자 이름
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="cardholderName"
                value={cardDetails.cardholderName}
                onChange={handleChange}
              />
              <Typography variant="body2" sx={{ mt: 2 }}>
                정산 담당자 이메일
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                type="email"
                name="email"
                value={cardDetails.email}
                onChange={handleChange}
                error={
                  !validateEmail(cardDetails.email) &&
                  cardDetails.email.length > 0
                }
                helperText={
                  !validateEmail(cardDetails.email) &&
                  cardDetails.email.length > 0
                    ? "유효한 이메일 주소를 입력해주세요."
                    : ""
                }
              />
              <Typography variant="body2" sx={{ mt: 2 }}>
                라이센스 결제 인원수
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                name="numberOfApplicants"
                value={numberOfApplicants}
                onChange={handleApplicantChange}
                inputProps={{ maxLength: 3 }} // 최대 3자리 입력
              />
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ mb: 3, fontWeight: "bold", color: "grey" }}
            >
              선택한 요금제
            </Typography>
            <Box
              sx={{
                marginY: 5,
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {cardDetails.productType}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginY: 2,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold", mr: 1 }}>
                  {cardDetails.productPrice.toLocaleString()} 원
                </Typography>
                <Typography variant="body2">/ 월별, 1인당</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                marginY: 5,
              }}
            >
              <Typography sx={{ fontWeight: "bold", color: "grey", mb: 2 }}>
                총 결제 금액
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: "bold", mr: 1 }}>
                {(
                  (Number(numberOfApplicants) || 1) *
                  Number(cardDetails.productPrice)
                ).toLocaleString()}{" "}
                원
              </Typography>
            </Box>
            <Box
              sx={{
                marginY: 5,
              }}
            >
              <Typography sx={{ fontWeight: "bold", color: "grey", mb: 2 }}>
                결제 방식
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                {planType === "monthly"
                  ? "카드 월간정기결제"
                  : "카드 년간정기결제"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginY: 5,
              }}
            >
              <Typography sx={{ fontWeight: "bold", color: "grey", mb: 2 }}>
                결제 일
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                {planType === "monthly" ? "매월 10일" : "매년 1월 1일"}
              </Typography>
            </Box>
            <Link href="https://example.com/terms" target="_blank">
              정기과금 이용약관
            </Link>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              }
              label="약관 내용을 확인하였고, 이에 동의합니다."
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!termsAccepted}
              onClick={handleSubmit}
              sx={{ mt: 2 }}
            >
              정기결제 신청
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreditCardPage;
