import PaymentBoardRegisterPage from "../pages/paymentBoard/paymentBoardRegisterPage";
import PaymentBoardListPage from "../pages/paymentBoard/paymentBoardListPage";
import PaymentBoardDetailPage from "../pages/paymentBoard/paymentBoardListDetailPage";


const PaymentBoardRoutes = [
  
  {
    path: "/payment-board-register",
    component: PaymentBoardRegisterPage,
  },
  {
    path: "/payment-board-list",
    component: PaymentBoardListPage,
    allowedRoles: ["1"],
  },
  {
    path: "/payment-board-detail/:id",
    component: PaymentBoardDetailPage,
    allowedRoles: ["1"],
  },



];

export default PaymentBoardRoutes;
