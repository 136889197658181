import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExperienceButton from "../../components/ExperienceButton";
const CompetitivePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            textAlign="center"
            marginTop={5}
            marginBottom={3}
            style={{ animation: "slide-up 1s ease-out" }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                // fontSize: "24px",
                fontFamily: "'NanumSquareNeoH'",
                fontWeight: "bold",
                color: "black",
                // fontStyle: "italic",
                lineHeight: "2",
                "@media (max-width: 800px)": {
                  fontSize: "25px", //
                },
              }}
            >
              <span style={{ color: " #0057af" }}>합리적 도입가</span>와{" "}
              <span style={{ color: " #0057af" }}>빠른 구축</span>으로<br></br>
              <span style={{ color: " #0057af" }}>보안</span>과{" "}
              <span style={{ color: " #0057af" }}>편리성</span>을 경험해 보세요
            </Typography>
            <ExperienceButton></ExperienceButton>
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "red",
          // padding: 5,
        }}
        textAlign="center"
        marginTop={5}
        marginBottom={3}
        style={{ animation: "slide-little-up 1s ease-out" }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'NanumSquareNeoH'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          공공시장 경쟁력
        </Typography>
        <Box
          sx={{
             backgroundImage: "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            marginBottom: 10,
            padding: 0,
          }}
        >
          <Box
            component="img"
            src="/images/introduce/competitive/8page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'NanumSquareNeoH'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "05px", //
            },
          }}
        >
          경쟁사 비교
        </Typography>
        <Box
          sx={{
             backgroundImage: "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            marginBottom: 10,
            padding: 0,
          }}
        >
          <Box
            component="img"
            src="/images/introduce/competitive/9page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'NanumSquareNeoH'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          제품 개발 로드맵
        </Typography>
        <Box
          sx={{
             backgroundImage: "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            marginBottom: 10,
            padding: 0,
          }}
        >
          <Box
            component="img"
            src="/images/introduce/competitive/17page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'NanumSquareNeoH'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          지적재산권 및 기술인증
        </Typography>
        <Box
          sx={{
             backgroundImage: "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            marginBottom: 10,
            padding: 0,
          }}
        >
          <Box
            component="img"
            src="/images/introduce/competitive/18page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'NanumSquareNeoH'",
            fontWeight: 900,
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          회사개요
        </Typography>
        <Box
          sx={{
             backgroundImage: "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            marginBottom: 10,
            padding: 0,
          }}
        >
          <Box
            component="img"
            src="/images/introduce/competitive/18page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CompetitivePage;
