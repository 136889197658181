import { Box, CardMedia, Container, Typography } from "@mui/material";

const LogvisorPage = () => {
  return (
    <>
      <Container
        sx={{
          mt: 20,
          mb: 20,
          "@media (max-width: 800px)": {
            mt:10,
            mb: 10
          },
        }}
      ></Container>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/*  소잃고 외양간 고친다. */}
        <Box
          className="container"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",

            gap: 5,
            mb: 20,
            "@media (max-width: 1000px)": {
              flexDirection: "column", // 화면 너비가  이하일 때 세로로 정렬
              alignItems: "center",
              mb: 15,
            },
          }}
        >
          {/* 글 세로 세트 */}
          <Box
            className={"fade-in-slow"}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontSize: "30px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 0,
                "@media (max-width: 1500px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "20px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              소잃고 외양간 고친다?
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "50px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 5,
                "@media (max-width: 1500px)": {
                  fontSize: "40px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              여러분의 PC는 안전하십니까?
            </Typography>
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "20px",
                fontFamily: "'NanumSquareEB'",
                fontWeight: "900",
                color: "#3f3a39",
                lineHeight: "1.5",
                marginBottom: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              LogVisor란? Log(기록) + superVisor(감독관)의 융합 합성어입니다.
            </Typography>

            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "18px",
                fontFamily: "'NanumSquareR'",
                fontWeight: "600",
                color: "#3f3a39",
                lineHeight: "1.5",
                marginBottom: 5,
                "@media (max-width: 800px)": {
                  fontSize: "15px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              LogVisor는 '도둑이 문을 열고 들어가지 못한다면 어떠한 일도
              <br />
              일어나지 않는다'라는 상식에서 출발했습니다.
              <br />
              LogVisor는 PC(Device) 로그인 전용 솔루션으로서 안티 바이러스
              백신과 <br />
              같이 보편적인 국민보안솔루션으로 성장하는 것은 물론 전세계의
              <br />
              표준이 되는 것이 목표입니다.
            </Typography>
          </Box>

          <CardMedia
            // className={"slide-in-right-01"}
            className={"fade-in-slow"}
            component="img"
            image="/images/제품소개/01 제품소개/합본.png"
            alt="safe-pc-life"
            sx={{
              // position: "relative",F
              // maxWidth: 500,
              maxWidth: "35%",
              // height: "auto",
              objectFit: "contain",
              // transform: "translate(-200px, 180px)",
              "@media (max-width: 1500px)": {
                // transform: "translate(-50px, 180px)",
                // order: 1, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                // marginBottom: 5,
              },
              "@media (max-width: 1000px)": {
                // transform: "translate(0px, 0px)",
                maxWidth: "50%",
                // order: 1, // 기본 순서는 변경하지 않음, 반응형 시 두 번째로 표시
                // marginBottom: 5,
              },
            }}
          />
        </Box>
        {/* pc 로그인보안 왜필요하ㅏㄹ까요 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 30,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          {/* 글 가로로 */}
          <Box
            className={"fade-in-slow"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // 좌우 간격
              flexWrap: "wrap", // 화면 크기에 따라 줄바꿈
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              PC 로그인 보안,
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              왜 필요할까요?
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "60%",
              // border: "20px solid transparent", // 기본 테두리 크기
              border: "20px solid transparent", // 기본 테두리 크기
              borderImage: `url('${encodeURI(
                "/images/제품소개/02 제품소개/02.png"
              )}') 30 round`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          >
            <CardMedia
              component="img"
              image="/images/제품소개/02 제품소개/03.png"
              alt="image-logvisor"
              sx={{
                // maxWidth: 500,
                maxWidth: "60%",
                // height: "auto",
                objectFit: "contain",
                "@media (max-width: 1500px)": {
                  maxWidth: "70%",
                },
                "@media (max-width: 1000px)": {
                  maxWidth: "80%",
                },
                "@media (max-width: 800px)": {
                  maxWidth: "90%",
                },
              }}
            />
          </Box>

          <CardMedia
            component="img"
            image="/images/제품소개/02 제품소개/합본1.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "40%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
          <CardMedia
            component="img"
            image="/images/제품소개/02 제품소개/합본2.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "40%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>

        {/* AD환경에서 랜섬웨어~ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 30,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          {/* 글 가로로 */}
          <Box
            className={"fade-in-slow"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // 좌우 간격
              flexWrap: "wrap", // 화면 크기에 따라 줄바꿈
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              AD 환경에서
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              랜섬웨어 선제적 방어 가능
            </Typography>
          </Box>

          <CardMedia
            component="img"
            image="/images/제품소개/03 제품소개/합본.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "40%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>

        {/* 타 솔루션과 확장가능 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 30,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          {/* 글 가로로 */}
          <Box
            className={"fade-in-slow"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // 좌우 간격
              flexWrap: "wrap", // 화면 크기에 따라 줄바꿈
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              타 솔루션과
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              확장 가능
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              (구축형 도입시)
            </Typography>
          </Box>

          <CardMedia
            component="img"
            image="/images/제품소개/04 제품소개/합본.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "35%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>

        {/* 인증받은 경쟁력 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 5,
            mb: 30,
            "@media (max-width: 1000px)": {
              mb: 20,
            },
          }}
        >
          {/* 글 가로로 */}
          <Box
            className={"fade-in-slow"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // 좌우 간격
              flexWrap: "wrap", // 화면 크기에 따라 줄바꿈
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoB'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              인증받은
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: "45px", // 41pt에 해당하는 px 크기
                fontFamily: "'NanumSquareNeoH'", // 나눔스퀘어네오H 폰트 지정
                fontWeight: "900",
                color: "#4B95D1",
                lineHeight: "1.5",
                marginBottom: 3,
                "@media (max-width: 1500px)": {
                  fontSize: "35px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 1000px)": {
                  fontSize: "30px", // 작은 화면에서는 적절히 조정
                },
                "@media (max-width: 800px)": {
                  fontSize: "25px", // 작은 화면에서는 적절히 조정
                },
              }}
            >
              경쟁력
            </Typography>
          </Box>

          <CardMedia
            component="img"
            image="/images/제품소개/05 제품소개/합본1.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "40%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
          <CardMedia
            component="img"
            image="/images/제품소개/05 제품소개/합본2.png"
            alt="image-logvisor"
            sx={{
              // maxHeight: 500,
              // maxWidth: 500,
              maxWidth: "40%",
              // height: "auto",
              objectFit: "contain",
              "@media (max-width: 1500px)": {
                maxWidth: "70%",
              },
              "@media (max-width: 1000px)": {
                maxWidth: "80%",
              },
              "@media (max-width: 800px)": {
                maxWidth: "90%",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LogvisorPage;
