import PaymentPage from "../pages/payment/PaymentPage";
import OrderRegisterPage from "../pages/order/OrderRegister";
import CreditCardPage from "../pages/payment/CredeitCardPage";

const OrderRoutes = [
  {
    path: "/payment-system",
    component: PaymentPage,
  },
  {
    path: "/credit-card",
    component: CreditCardPage,
  },
  {
    path: "/order",
    component: OrderRegisterPage
  },



];

export default OrderRoutes;
