import React, { Dispatch, useEffect, useState } from "react";

import { RootState } from "@/index";
import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import UserLogsFilterByDate from "../../components/user/UserLogsFilterByDate";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { formatISO } from "date-fns"; //
import UserLogsForm from "../../components/user/userLogsForm";
import { fetchPagedAllLogs } from "../../redux/user/userActions";

type ChartType =
  | "daily-logins"
  | "daily-anomalies"
  | "off-hours-login"
  | "weekend-logins"
  | "weekend-work-hours-logins"
  | "weekend-off-hours-logins";

const chartTitles: Record<ChartType, string> = {
  "daily-logins": "최근 7일 로그인 현황",
  "daily-anomalies": "최근 7일 이상징후 현황",
  "off-hours-login": "최근 7일 근무시간 외 로그인 현황",
  "weekend-logins": "최근 한달 내 주말 로그인 현황",
  "weekend-work-hours-logins": "최근 한달 내 주말 근무시간 내 로그인 현황",
  "weekend-off-hours-logins": "최근 한달 내 주말 근무시간 외 로그인 현황",
};

const logFilters = [
  { label: "모든 로그", value: "" },
  { label: "모든 이상 징후", value: "daily-anomalies" },
  { label: "근무시간 외", value: "off-hours-login" },
  { label: "주말 로그인", value: "weekend-logins" },
  { label: "주말 근무시간 내", value: "weekend-work-hours-logins" },
  { label: "주말 근무시간 외", value: "weekend-off-hours-logins" },
];

const UserLogsPage: React.FC = () => {
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined); // undefined로 설정
  const [endDate, setEndDate] = useState<Date | undefined>(undefined); // undefined로 설정
  const [type, setType] = useState<ChartType | "">("");
  const { pagedLogs, loading, error } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // 화면 크기에 따라 항목 수 결정
  const determineItemsPerPage = () => {
    const height = window.innerHeight;
    return height < 1200 ? 12 : 24;
  };
  const [itemsPerPage, setItemsPerPage] = useState(determineItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      console.log("handle Resize 함수 작동");
      const newItemsPerPage = determineItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);

  //log가져오는 로직
  useEffect(() => {
    const values = queryString.parse(location.search);
    const page = parseInt((values.page as string) || "1", 10);
    const typeFromQuery = (values.type as string) || "";
    const filterFromQuery = (values.filter as string) || "";
    const startDateStr = values.startDate as string | undefined;
    const endDateStr = values.endDate as string | undefined;
    let startDate;
    let endDate;
    if (startDateStr) {
      console.log("startDateStr:", startDateStr);
      startDate = new Date(startDateStr);
      setStartDate(startDate);
    }

    if (endDateStr) {
      endDate = new Date(endDateStr);
      setEndDate(endDate);
    }
    // 여기에서 typeFromQuery를 ChartType으로 캐스팅하기 전에 검증을 수행합니다.
    if (Object.keys(chartTitles).includes(typeFromQuery)) {
      setType(typeFromQuery as ChartType);
    } else {
      setType(""); // 유효하지 않은 경우 빈 문자열로 설정
    }

    if (filterFromQuery) {
      setFilter(filterFromQuery);
    }
    // const startDate = Array.isArray(values.startDate)
    //   ? new Date(values.startDate[0] || "")
    //   : values.startDate
    //   ? new Date(values.startDate)
    //   : undefined;

    // const endDate = Array.isArray(values.endDate)
    //   ? new Date(values.endDate[0] || "")
    //   : values.endDate
    //   ? new Date(values.endDate)
    //   : undefined;

    console.log("page:", page);
    console.log("size:", itemsPerPage);

    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    console.log("type:", typeFromQuery);
    console.log("filter:", filterFromQuery);

    dispatch(
      fetchPagedAllLogs(
        page,
        itemsPerPage,
        startDate,
        endDate,
        typeFromQuery,
        filterFromQuery
      )
    );
  }, [dispatch, location.search, itemsPerPage]);

  const handleResetDates = () => {
    setStartDate(undefined); // undefined로 설정
    setEndDate(undefined); // undefined로 설정
    setFilter("");
    navigate(`/logs-list?${type ? `type=${type}&` : ""}page=1`);
  };

  //페이지버튼 누를때
  const handlePageChange = (pageNumber: number) => {
    // const formattedStartDate = startDate
    //   ? formatISO(startDate, { representation: "date" }) // 날짜를 ISO 문자열로 포맷
    //   : undefined;
    // const formattedEndDate = endDate
    //   ? formatISO(endDate, { representation: "date" }) // 날짜를 ISO 문자열로 포맷
    //   : undefined;

    //이렇게해야 날짜만 추출하여 쿼리가 깔끔해짐 이렇게안해도 작동은함
    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : undefined;
    const formattedEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : undefined;

    console.log("formattedStartDate", formattedStartDate);
    console.log("formattedEndDate", formattedEndDate);
    console.log("startDate", startDate?.toISOString());
    console.log("endDate", endDate);

    // queryString을 만들 때 undefined 체크하여 쿼리에 추가
    const queryString = `?${type ? `type=${type}&` : ""}${
      filter ? `filter=${filter}&` : ""
    }page=${pageNumber}${
      formattedStartDate ? `&startDate=${formattedStartDate}` : ""
    }${formattedEndDate ? `&endDate=${formattedEndDate}` : ""}`;
    navigate(`/logs-list${queryString}`);
    //navigate로 다시 리렌더링하는데 굳이 밑에꺼 할필요가있겠니?
    // dispatch(
    //   fetchPagedUserLog(
    //     userId,
    //     pageNumber,
    //     itemsPerPage,
    //     formattedStartDate ? new Date(formattedStartDate) : undefined,
    //     formattedEndDate ? new Date(formattedEndDate) : undefined
    //   )
    // );
  };

  const handleDateChange = (newStartDate?: Date, newEndDate?: Date) => {
    if (newStartDate && newEndDate && newStartDate <= newEndDate) {
      const startIsoDate = formatISO(newStartDate, { representation: "date" });
      const endIsoDate = formatISO(newEndDate, { representation: "date" });
      console.log("startIsoDate", startIsoDate);
      console.log("endIsoDate", endIsoDate);

      // URL 쿼리스트링 업데이트 및 페이지 이동
      navigate(
        `/logs-list?page=1&${type ? `type=${type}&` : ""}${
          filter ? `filter=${filter}&` : ""
        }startDate=${startIsoDate}&endDate=${endIsoDate}`
      );

      // 상태 업데이트
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      // 선택적으로 에러 상태 처리나 사용자에게 알림
      alert("시작 날짜는 종료 날짜와 같거나 이전이어야 합니다.");
    }
  };
  
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const filterValue = event.target.value;

    if (startDate && endDate) {
      navigate(
        `/logs-list?page=1&${type ? `type=${type}&` : ""}${
          filterValue ? `filter=${filterValue}&` : ""
        }startDate=${startDate}&endDate=${endDate}`
      );
    } else {
      navigate(
        `/logs-list?page=1&${type ? `type=${type}&` : ""}${
          filterValue ? `filter=${filterValue}` : ""
        }`
      );
    }
    setFilter(event.target.value);
  };

  const handleResetList = () => {
    navigate("/logs-list?page=1");
  };

  // Determine the page title based on the type
  const pageTitle = type ? chartTitles[type] : "로그 조회";

  console.log("UserLogsPage렌더링");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="container mt-3" style={{ minWidth: "300px" }}>
          {/*맨윗줄 유저정보랑  기간설정*/}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h5" component="h2" fontWeight="bold">
              {pageTitle}
            </Typography>

            <Box display="flex" alignItems="center" gap={3}>
              <FormControl
                variant="outlined"
                sx={{
                  width: 200,
                  minWidth: 100,
                }}
              >
                <InputLabel id="log-filter-select-label">로그 필터</InputLabel>
                <Select
                  labelId="Flog-filter-select-label"
                  id="log-filter-select"
                  value={filter}
                  label="전체 보기"
                  onChange={handleFilterChange}
                >
                  {logFilters.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {!type && ( // type이 비어있는 경우(true)에만 아래 컴포넌트를 렌더링합니다.
                <UserLogsFilterByDate
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handleDateChange={handleDateChange}
                  handleResetDates={handleResetDates}
                />
              )}
            </Box>
          </Box>
          {/* 메인컨텐츠 */}
          <Box>
            <div className="container mt-3" style={{ minWidth: "300px" }}>
              <UserLogsForm
                pagedLogs={pagedLogs}
                handlePageChange={handlePageChange}
                handleResetList={handleResetList}
              />
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default UserLogsPage;
