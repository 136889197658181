import NoticeBoardUpdateForm from "../../components/noticeBoard/noticeBoardUpdateForm";
import React from "react";

const NoticeBoardUpdatePage: React.FC = () => {
  return (
    <div className="container mt-5">
      <NoticeBoardUpdateForm />
    </div>
  );
};

export default NoticeBoardUpdatePage;
