import {
  FETCH_NOTICEBOARDLIST_FAILURE,
  FETCH_NOTICEBOARDLIST_REQUEST,
  FETCH_NOTICEBOARDLIST_SUCCESS,
  FETCH_PAGED_NOTICEBOARDLIST_SUCCESS,
  FETCH_NOTICEBOARD_FAILURE,
  FETCH_NOTICEBOARD_REQUEST,
  FETCH_NOTICEBOARD_SUCCESS,
  NoticeBoardActionTypes,
  NoticeBoardState,
  DELETE_NOTICEBOARD_REQUEST,
  DELETE_NOTICEBOARD_SUCCESS,
  DELETE_NOTICEBOARD_FAILURE,
  UPDATE_NOTICEBOARD_FAILURE,
  UPDATE_NOTICEBOARD_SUCCESS,
  UPDATE_NOTICEBOARD_REQUEST,
} from "../../types/noticeBoardTypes";

const initialState: NoticeBoardState = {
  loading: false,
  noticeBoard: null,
  noticeBoardList: [],
  pagedArticles: null,
  error: null,
};

// Reducer
export const noticeBoardReducer = (
  state = initialState,
  action: NoticeBoardActionTypes
): NoticeBoardState => {
  switch (action.type) {
    case FETCH_NOTICEBOARDLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTICEBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        noticeBoardList: action.payload,
        error: "",
      };
    case FETCH_PAGED_NOTICEBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pagedArticles: action.payload,
        error: "",
      };
    case FETCH_NOTICEBOARDLIST_FAILURE:
      return {
        ...state,
        loading: false,
        noticeBoardList: [],
        error: action.payload,
      };

    case FETCH_NOTICEBOARD_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_NOTICEBOARD_SUCCESS:
      return { ...state, loading: false, noticeBoard: action.payload };
    case FETCH_NOTICEBOARD_FAILURE:
      return { ...state, loading: false, error: action.error };

    case DELETE_NOTICEBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_NOTICEBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        noticeBoardList: state.noticeBoardList.filter(
          (noticeBoard) => noticeBoard.noticeBoardId !== action.payload
        ),
      };
    case DELETE_NOTICEBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case UPDATE_NOTICEBOARD_REQUEST:
        return { ...state, loading: true };
      case UPDATE_NOTICEBOARD_SUCCESS:
        return {
          ...state,
          loading: false,
          noticeBoard: action.payload,
          noticeBoardList: state.noticeBoardList.map(nb =>
            nb.noticeBoardId === action.payload.noticeBoardId ? action.payload : nb
          )
        };
      case UPDATE_NOTICEBOARD_FAILURE:
        return { ...state, loading: false, error: action.payload };
      
    default:
      return state;
  }
};
