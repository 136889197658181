import React from "react";
import {
  Box,
  Typography,
  Paper,
  Link,
  Tooltip,
  IconButton,
} from "@mui/material";
import { NoticeBoard } from "../../types/noticeBoardTypes";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
interface NoticeBoardDetailFormProps {
  noticeBoard: NoticeBoard;
  onEdit: () => void; //
  onDelete: () => void; //
}

const NoticeBoardDetailForm: React.FC<NoticeBoardDetailFormProps> = ({
  noticeBoard,
  onEdit,
  onDelete,
}) => {
  const manager = useSelector((state: any) => state.manager.checkRole);
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // const fileDownloadUrl = `https://logvisor.com/images/notice-board/${noticeBoard.fileUrl}`; //배포
  // const fileDownloadUrl = `http://localhost:3587/images/notice-board/${noticeBoard.fileUrl}`;//개발
  const fileDownloadUrl = `${process.env.REACT_APP_API_URL}${noticeBoard.fileUrl}`;
  console.log("process.env.REACT_APP_API_URL:", process.env.REACT_APP_API_URL);

  function determineIcon(fileName: string) {
    if (/\.pdf$/i.test(fileName)) {
      return <PictureAsPdfIcon />;
    } else if (/\.(jpg|jpeg|png|gif)$/i.test(fileName)) {
      return <ImageIcon />;
    } else {
      return <InsertDriveFileIcon />;
    }
  }

  return (
    <Paper sx={{ padding: "20px", margin: "20px", minWidth: 320 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h5"
          gutterBottom
          sx={{ display: "inline", fontWeight: "bold", marginLeft: 5 }}
        >
          {noticeBoard.title}
        </Typography>
        {manager && manager?.userCode === '1' && (
          <Box>
            <Tooltip title="Edit">
              <IconButton onClick={onEdit} color="primary">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={onDelete} color="error">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Typography
          variant="body1"
          gutterBottom
          sx={{ display: "inline", fontWeight: "light" }}
        >
          {noticeBoard.createdTime ? formatDate(noticeBoard.createdTime) : ""}
        </Typography>
        {/* <Typography
          variant="body1"
          gutterBottom
          sx={{ display: "inline", marginX: 5 }}
        >
          글번호.{noticeBoard.noticeBoardId}
        </Typography> */}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          mt: 2,
          flexDirection: { xs: "column", lg: "row" }, // 600px 이하에서는 칼럼 방향
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향
          }}
        >
          <Typography variant="body1" sx={{ display: "inline", mx: 2 }}>
            관리자ID: {noticeBoard.managerId}
          </Typography>

          <Typography variant="body1" sx={{ display: "inline", mx: 2 }}>
            작성자: {noticeBoard.author}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mt: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" }, // 600px 이하에서는 칼럼 방향
          }}
        ></Box>
      </Box>
      <Box sx={{ marginY: 10 }}>
        <Typography
          variant="body1"
          sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          {noticeBoard.inquiryDetail}
        </Typography>
      </Box>
      {noticeBoard.fileUrl && (
        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="h5" sx={{ marginY: 5, fontWeight: "bold" }}>
            첨부 파일
          </Typography>
          <Link
            href={fileDownloadUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {determineIcon(noticeBoard.fileUrl)}
            <Typography sx={{ ml: 1 }}>
              {noticeBoard.fileUrl.split("/").pop()}
            </Typography>
          </Link>
        </Box>
      )}
    </Paper>
  );
};

export default NoticeBoardDetailForm;
