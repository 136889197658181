import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducers";
import {
  Manager,
  SIGN_IN,
  SignInPayload,
  LOGOUT,
  LOGIN_SUCCESS,
  ErrorResponse,
  CHECK_MANAGER_SUCCESS,
  CHECK_MANAGER_START,
  CHECK_MANAGER_FAILURE,
  FETCH_MANAGERLIST_SUCCESS,
  FETCH_MANAGERLIST_FAILURE,
  FETCH_MANAGERLIST_REQUEST,
  ManagerActionTypes,
  DELETE_MANAGER_FAILURE,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER,
  FETCH_MANAGER_REQUEST,
  FETCH_MANAGER_SUCCESS,
  FETCH_MANAGER_FAILURE,
  ManagerUpdate,
  PWINIT_MANAGER_SUCCESS,
  PWINIT_MANAGER_FAILURE,
  PWINIT_MANAGER,
  PagedResponse,
  FETCH_PAGED_MANAGERLIST_SUCCESS,
  FETCH_MASTER_KEY_REQUEST,
  FETCH_MASTER_KEY_SUCCESS,
  FETCH_MASTER_KEY_FAILURE,
} from "../../types/managerTypes"; // userTypes.ts에서 타입들을 가져옵니다.
import mainRequest from "../../api/mainRequest";
import { Company } from "../../types/userTypes";

export const checkManager =
  (): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch({ type: CHECK_MANAGER_START });
    try {
      console.log("checkManager 액션 접근");
      const config = {
        headers: {
          // Authorization: "Bearer " + localStorage.getItem("token"),
          Authorization: localStorage.getItem("token"),
        },
      };
      console.log("config.headers.Authorization", config.headers.Authorization);

      const response = await mainRequest.get(
        "/manager/onlymanager/managerinfo",
        config
      );
      dispatch({
        type: CHECK_MANAGER_SUCCESS,
        payload: response.data,
      });
      console.log("통신후response.data", response.data);
      // dispatch({
      //   type: MANAGER_INFO,
      //   payload: response.data,
      // });

      return Promise.resolve();
    } catch (error) {
      console.log("checkkManager 액션 실패!");
      console.error(error);
      dispatch({ type: CHECK_MANAGER_FAILURE });
      return Promise.reject(); // 실패 시 Promise.reject 반환
    }
  };

// export const checkManager =
//   (): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
//   async (dispatch, getState) => {
//     return new Promise<void>(async (resolve, reject) => {
//       dispatch({ type: CHECK_MANAGER_START });
//       try {
//         console.log("checkManager 액션 접근");
//         const config = {
//           headers: {
//             // Authorization: "Bearer " + localStorage.getItem("token"),
//             Authorization: localStorage.getItem("token"),
//           },
//         };
//         console.log(
//           "config.headers.Authorization",
//           config.headers.Authorization
//         );

//         const response = await mainRequest.get(
//           "/manager/onlymanager/managerinfo",
//           config
//         );
//         dispatch({
//           type: CHECK_MANAGER_SUCCESS,
//           payload: response.data,
//         });
//         console.log("통신후response.data", response.data);
//         // dispatch({
//         //   type: MANAGER_INFO,
//         //   payload: response.data,
//         // });

//         resolve();
//       } catch (error) {
//         console.log("checkkManager 액션 실패!");
//         console.error(error);
//         dispatch({ type: CHECK_MANAGER_FAILURE });
//         reject(error); // 실패 시 Promise.reject 반환
//       }
//     });
//   };

export const signUp =
  (
    managerData: Manager,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("signUp-try");

      const response = await mainRequest.post("/manager/sign-up", managerData);
      alert("회원가입 성공");
      // if (getState().user.isLoggedIn) {
      // }
      navigate("/sign-in");
    } catch (error) {
      console.log("sign up실패!");
      console.error("error: ", error);
      const axiosError = error as AxiosError; // AxiosError로 타입 캐스팅
      console.error("axiosError: ", axiosError);

      if (axiosError.response && axiosError.response.data) {
        // 에러 메시지가 있는지 확인하고 처리
        const data = axiosError.response.data as ErrorResponse;
        console.log("data", data);
        console.log("data.message", data.message);
        if ("message" in data && typeof data.message === "string") {
          if (data.message.includes("이메일")) {
            toast.error("이메일이 중복되었습니다.");
          } else if (data.message.includes("전화번호")) {
            toast.error("전화번호가 중복되었습니다.");
          } else {
            toast.error("회원가입 실패");
          }
        } else {
          // 'message' 속성이 없거나 문자열이 아닌 경우
          toast.error("알 수 없는 오류가 발생했습니다.");
        }
      } else {
        // axiosError.response가 없는 경우
        toast.error("서버 응답 없음");
      }
    }
  };

export const signIn =
  (
    managerLoginData: SignInPayload,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("signIn- try");
      // const formBody = Object.keys(userData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(userData[key])).join('&');

      const response = await mainRequest.post(
        "/login", // Change the endpoint to the sign in endpoint
        managerLoginData
        // {
        //   headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //   },
        // }
      );
      console.log("sigin in, response후 ");
      dispatch({
        type: SIGN_IN,
        payload: {
          managerId: response.data.managerId,
          email: response.data.email,
          company: response.data.company,
          crn: response.data.crn,
          tel: response.data.tel,
          userCode: response.data.userCode,
          isLoggedIn: true,
        },
      });

      console.log("response.data:", response.data);
      console.log("response:", response);
      console.log("response.headers:", response.headers);
      console.log(
        "response.headers.authorization:",
        response.headers.authorization
      );
      localStorage.setItem("token", response.headers.authorization);
      alert("로그인 성공");
      navigate("/");
      //로그인 후 사용자정보 state에 넣기, 토큰이용한 검증
    } catch (error) {
      console.log("sign in 실패!");
      console.error(error);

      if (axios.isAxiosError(error)) {
        // Axios 에러 처리
        if (error.response) {
          // 서버 응답이 있는 경우
          const { status } = error.response;
          switch (status) {
            case 401:
              alert("로그인 정보가 정확하지 않습니다. 다시 시도해 주세요.");
              break;
            case 404:
              alert("서버를 찾을 수 없습니다. 나중에 다시 시도해 주세요.");
              break;
            default:
              alert("알 수 없는 오류가 발생했습니다. 관리자에게 문의하세요.");
          }
        } else {
          // 서버 응답이 없는 경우 (네트워크 오류 등)
          alert(
            "서버로부터 응답을 받지 못했습니다. 네트워크 연결을 확인해 주세요."
          );
        }
      } else {
        // Axios 외의 오류 처리
        alert("로그인 시도 중 예상치 못한 오류가 발생했습니다.");
      }
    }
  };

// actions/userActions.js

export const logout =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      // const response = await mainRequest.post("/logout");

      // 로그인 상태를 로그아웃 상태로 변경
      dispatch({ type: LOGOUT });

      // localStorage에서 토큰 제거
      localStorage.removeItem("token");
      alert("로그아웃 완료");
    } catch (error) {
      console.error(error);
    }
  };

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const checkLoginStatus = () => {
  return (dispatch: Dispatch) => {
    const token = localStorage.getItem("token");
    console.log("checkLoginStatus");
    if (token) {
      dispatch(loginSuccess());
    } else {
      dispatch({ type: LOGOUT });
    }
  };
};

export const checkManagerIdDuplication =
  (managerId: string) => async (dispatch: Dispatch) => {
    const response = await mainRequest.post(`/manager/check-id/${managerId}`);
    return response.data;
  };

export const fetchManagerList = () => {
  return (dispatch: any) => {
    dispatch(fetchManagerListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/manager/onlyadmin/list", config)
      .then((response) => {
        dispatch(fetchManagerListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchManagerListFailure(error.message));
      });
  };
};


export const fetchPagedManagerList = ({
  page = 1,
  size=12,
  searchText = "",
  selectedCompany = ""}
) => {
  return (dispatch: any) => {
    console.log("fetchPagedManagerList -action 진입");
    dispatch(fetchManagerListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: {
        page, // Add the page number to the request query parameters
        size,
        ...(searchText && { search: searchText }),
        ...(selectedCompany && { company: selectedCompany }),
      },
    };
    mainRequest
      .get("/manager/onlyadmin/list-page", config)
      .then((response) => {
        console.log("action-fetchList 스프링에서 가져온 reponse.data: ", response.data);
        dispatch(fetchPagedManagerListSuccess(response.data));
      })
      .catch((error) => {
        console.error("Error fetching manager list:", error);
        dispatch(fetchManagerListFailure(error.message));
      });
  };
};

export const fetchManagerListRequest = () => {
  return {
    type: FETCH_MANAGERLIST_REQUEST,
  };
};

export const fetchManagerListSuccess = (userList: Manager[]) => {
  return {
    type: FETCH_MANAGERLIST_SUCCESS,
    payload: userList,
  };
};
export const fetchPagedManagerListSuccess = (pagedManagers: PagedResponse<Manager>) => {
  return {
    type: FETCH_PAGED_MANAGERLIST_SUCCESS,
    payload: pagedManagers,
  };
};


export const fetchManagerListFailure = (error: string) => {
  return {
    type: FETCH_MANAGERLIST_FAILURE,
    payload: error,
  };
};

export const deleteManager =
  (
    managerId: string
  ): ThunkAction<Promise<void>, RootState, unknown, ManagerActionTypes> => // 반환 타입 변경
  (dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      dispatch({ type: DELETE_MANAGER });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        await mainRequest.delete(
          `/manager/onlymanager/delete/${managerId}`,
          config
        );
        dispatch({ type: DELETE_MANAGER_SUCCESS, payload: managerId });
        toast.success("관리자가 삭제되었습니다!");
        resolve();
      } catch (error) {
        const err = error as Error;
        dispatch({ type: DELETE_MANAGER_FAILURE, error: err.message });
        toast.error("관리자 삭제에 실패하였습니다!");
        reject(error);
      }
    });
  };

//데이터 return 하지 않는 버전
// export const fetchManager =
// (
//   managerId: string
// ): ThunkAction<Promise<void>, RootState, unknown, ManagerActionTypes> =>
// async (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     dispatch({ type: FETCH_MANAGER_REQUEST });

//     try {
//       const config = {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       };
//       const response = await mainRequest.get(
//         `/manager/onlymanager/detail/${managerId}`,
//         config
//       );
//       console.log("response.data", response.data);
//       dispatch({ type: FETCH_MANAGER_SUCCESS, payload: response.data });
//       resolve();
//       console.log("fetchmanager 성공")
//     } catch (error) {
//       const err = error as Error;
//       dispatch({ type: FETCH_MANAGER_FAILURE, error: err.message });
//       reject(err);
//     }
//   });
// };

//데이터 리턴하는 버전
export const fetchManager =
  (
    managerId: string
  ): ThunkAction<Promise<Manager>, RootState, unknown, ManagerActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_MANAGER_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/manager/onlyadmin/detail/${managerId}`,
          config
        );
        // console.log("fetchManager액션- response.data", response.data);
        dispatch({ type: FETCH_MANAGER_SUCCESS, payload: response.data });
        resolve(response.data);
        console.log("fetchManager액션- fetchmanager 성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_MANAGER_FAILURE, error: err.message });
        reject(err);
      }
    });
  };

export const fetchMyManagerInfo =
  (): ThunkAction<Promise<Manager>, RootState, unknown, ManagerActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_MANAGER_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/manager/onlymanager/detail`,
          config
        );
        // console.log("fetchManager액션- response.data", response.data);
        dispatch({ type: FETCH_MANAGER_SUCCESS, payload: response.data });
        resolve(response.data);
        console.log("fetchMyManagerInfo액션- fetchMyManagerInfo 성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_MANAGER_FAILURE, error: err.message });
        reject(err);
      }
    });
  };

export const updateManager =
  (
    managerData: ManagerUpdate,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("updateManager-try");
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await mainRequest.put(
        "/manager/onlymanager/update",
        managerData,
        config
      );
      alert("update Manager 성공");
      // if (getState().user.isLoggedIn) {
      // }
      navigate("/");
    } catch (error) {
      console.log("updateManager 실패!");
      console.error("error: ", error);
      const axiosError = error as AxiosError; // AxiosError로 타입 캐스팅
      console.error("axiosError: ", axiosError);

      if (axiosError.response && axiosError.response.data) {
        // 에러 메시지가 있는지 확인하고 처리
        const data = axiosError.response.data as ErrorResponse;
        console.log("data", data);
        console.log("data.message", data.message);
        if ("message" in data && typeof data.message === "string") {
          if (data.message.includes("이메일")) {
            toast.error("이메일이 중복되었습니다.");
          } else if (data.message.includes("전화번호")) {
            toast.error("전화번호가 중복되었습니다.");
          } else {
            toast.error("updateManager 실패");
          }
        } else {
          // 'message' 속성이 없거나 문자열이 아닌 경우
          toast.error("알 수 없는 오류가 발생했습니다.");
        }
      } else {
        // axiosError.response가 없는 경우
        toast.error("서버 응답 없음");
      }
    }
  };

export const pwInitializationManager =
  (
    managerId: string
  ): ThunkAction<Promise<void>, RootState, unknown, ManagerActionTypes> => // 반환 타입 변경
  (dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      dispatch({ type: PWINIT_MANAGER });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        await mainRequest.put(
          `/manager/onlyadmin/pwinit/${managerId}`,
          "",
          config
        );
        dispatch({ type: PWINIT_MANAGER_SUCCESS, payload: managerId });
        toast.success("비밀번호가 초기화 되었습니다.!");
        resolve();
      } catch (error) {
        const err = error as Error;
        dispatch({ type: PWINIT_MANAGER_FAILURE, error: err.message });
        toast.error("비밀번호가 초기화에 실패했습니다.");
        reject(error);
      }
    });
  };

export const fetchManagerIdByEmail =
  (email: string) => async (dispatch: Dispatch) => {
    const response = await mainRequest.post(
      `/manager/find-id-by-email/${email}`
    );
    return response.data;
  };

export const fetchManagerIdByTel =
  (phone: string) => async (dispatch: Dispatch) => {
    const response = await mainRequest.post(`/manager/find-id-by-tel/${phone}`);
    return response.data;
  };

export const getUniqueCompanyNamesOfManager =
  (): ThunkAction<Promise<Company[]>, RootState, unknown, ManagerActionTypes> =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/manager/onlyadmin/get-companys-manager`,
          config
        );
        resolve(response.data);
      } catch (error) {
        const err = error as Error;
        reject(err);
      }
    });
  };

// poc 테스트용
export const signUpTest =
  (
    managerData: any,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        // "http://localhost:3589/manager/test/sign-up",
        "https://logvisor.com:3589/manager/test/sign-up",
      
        managerData
      );
      alert("회원가입 성공");
      // if (getState().user.isLoggedIn) {
      // }
      navigate("/test/sign-in");
    } catch (error) {
      console.log("sign up실패!");
      console.error("error: ", error);
      const axiosError = error as AxiosError; // AxiosError로 타입 캐스팅
      console.error("axiosError: ", axiosError);

      if (axiosError.response && axiosError.response.data) {
        // 에러 메시지가 있는지 확인하고 처리
        const data = axiosError.response.data as ErrorResponse;
        console.log("data", data);
        console.log("data.message", data.message);
        if ("message" in data && typeof data.message === "string") {
          toast.error("회원가입 실패");
        } else {
          // 'message' 속성이 없거나 문자열이 아닌 경우
          toast.error("알 수 없는 오류가 발생했습니다.");
        }
      } else {
        // axiosError.response가 없는 경우
        toast.error("서버 응답 없음");
      }
    }
  };

export const signInTest =
  (
    managerLoginData: any,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("signInTest- try");
      console.log("response로 들어가기전 managerLoginData:", managerLoginData);

      const response = await axios.post(
        //"http://localhost:3589/login",
        "https://logvisor.com:3589/login",
        managerLoginData
      );
      console.log("sigin in TEST, response후 ");
 

      console.log("response.data:", response.data);
      console.log("response:", response);
      console.log("response.headers:", response.headers);
      console.log(
        "response.headers.authorization:",
        response.headers.authorization
      );
      localStorage.setItem("token", response.headers.authorization);
      alert("로그인TEST 성공");
      navigate("/test/login-status");
      //로그인 후 사용자정보 state에 넣기, 토큰이용한 검증
    } catch (error) {
      console.log("sign in TEST실패!");
      console.error(error);

      if (axios.isAxiosError(error)) {
        // Axios 에러 처리
        if (error.response) {
          // 서버 응답이 있는 경우
          const { status } = error.response;
          switch (status) {
            case 401:
              alert("로그인 정보가 정확하지 않습니다. 다시 시도해 주세요.");
              break;
            case 404:
              alert("서버를 찾을 수 없습니다. 나중에 다시 시도해 주세요.");
              break;
            default:
              alert("알 수 없는 오류가 발생했습니다. 관리자에게 문의하세요.");
          }
        } else {
          // 서버 응답이 없는 경우 (네트워크 오류 등)
          alert(
            "서버로부터 응답을 받지 못했습니다. 네트워크 연결을 확인해 주세요."
          );
        }
      } else {
        // Axios 외의 오류 처리
        alert("로그인 시도 중 예상치 못한 오류가 발생했습니다.");
      }
    }
  };


  export const signInTest_GET =
  (
    managerLoginData: any,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      console.log("signInTest- try");
      console.log("response로 들어가기전 managerLoginData:", managerLoginData);

      const response = await axios.get(
        //"http://localhost:3589/login",
         "https://logvisor.com:3589/login",
        {
          params: {
            id: managerLoginData.managerId,
            pass: managerLoginData.password,
          },
        }
      );
      console.log("sigin in TEST, response후 ");
 

      console.log("response.data:", response.data);
      console.log("response:", response);
      console.log("response.headers:", response.headers);
      console.log(
        "response.headers.authorization:",
        response.headers.authorization
      );
      localStorage.setItem("token", response.headers.authorization);
      alert("로그인TEST 성공");
      navigate("/test/login-status");
      //로그인 후 사용자정보 state에 넣기, 토큰이용한 검증
    } catch (error) {
      console.log("sign in TEST실패!");
      console.error(error);

      if (axios.isAxiosError(error)) {
        // Axios 에러 처리
        if (error.response) {
          // 서버 응답이 있는 경우
          const { status } = error.response;
          switch (status) {
            case 401:
              alert("로그인 정보가 정확하지 않습니다. 다시 시도해 주세요.");
              break;
            case 404:
              alert("서버를 찾을 수 없습니다. 나중에 다시 시도해 주세요.~~");
              break;
            default:
              alert("알 수 없는 오류가 발생했습니다. 관리자에게 문의하세요.");
          }
        } else {
          // 서버 응답이 없는 경우 (네트워크 오류 등)
          alert(
            "서버로부터 응답을 받지 못했습니다. 네트워크 연결을 확인해 주세요."
          );
        }
      } else {
        // Axios 외의 오류 처리
        alert("로그인 시도 중 예상치 못한 오류가 발생했습니다.");
      }
    }
  };

  
export const logoutTest =
(): ThunkAction<void, RootState, unknown, Action<string>> =>
async (dispatch) => {
  try {
    // const response = await mainRequest.post("/logout");

    // 로그인 상태를 로그아웃 상태로 변경
    dispatch({ type: LOGOUT });

    // localStorage에서 토큰 제거
    localStorage.removeItem("token");
    alert("로그아웃 완료");
  } catch (error) {
    console.error(error);
  }
};


export const fetchMasterKey =
  (): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_MASTER_KEY_REQUEST });
    try {
      const config = {
        headers: {
          // Authorization: "Bearer " + localStorage.getItem("token"),
          Authorization: localStorage.getItem("token"),
        },
      };
      console.log("config.headers.Authorization", config.headers.Authorization);

      const response = await mainRequest.get(
        "/manager/onlymanager/get-master-key",
        config
      );
      dispatch({
        type: FETCH_MASTER_KEY_SUCCESS,
        payload: response.data,
      });
      console.log("통신후response.data", response.data);
      // dispatch({
      //   type: MANAGER_INFO,
      //   payload: response.data,
      // });

      return Promise.resolve();
    } catch (error) {
      console.error(error);
      dispatch({ type: FETCH_MASTER_KEY_FAILURE });
      return Promise.reject(); // 실패 시 Promise.reject 반환
    }
  };

  export const updateMasterKey =
  (
    formData:{newMasterKey:string}
  ): ThunkAction<Promise<void>, RootState, unknown, ManagerActionTypes> => // 반환 타입 변경
  (dispatch) => {
    return new Promise<void>(async (resolve, reject) => {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      try {
        const response = await mainRequest.put(
          "/manager/onlymanager/update-master-key",
          formData,
          config
        );
        toast.success("마스터 키가 수정 되었습니다.!");
        resolve();
      } catch (error) {
        const err = error as Error;
        toast.error("마스터키 수정에 실패했습니다.");
        reject(error);
      }
    });
  };