import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ExperienceButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 10,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          // variant="outlined"
          onClick={() => navigate("/sign-up")}
          sx={{
            minWidth: 110,
            width: 200,
            height: 50,
            fontWeight: "bold",
            borderRadius: "20px",
            fontSize: 18,
            fontFamily: "'NanumSquareR'",
            color: "white",
            backgroundColor: " #0057af", // Default background color
            "&:hover": {
              backgroundColor: "#1976d2", // Background color on hover
              color: "white",
            },
          }}
        >
          30일 무료체험
        </Button>
        <Button
          variant="contained"
          // variant="outlined"
          onClick={() => navigate("/payment-board-register")}
          sx={{
            minWidth: 110,
            width: 200,
            height: 50,
            fontWeight: "bold",
            borderRadius: "20px",
            fontSize: 18,
            fontFamily: "'NanumSquareR'",
            color: "white",
            backgroundColor: " #0057af", // Default background color
            "&:hover": {
              backgroundColor: "#1976d2", // Background color on hover
              color: "white",
            },
          }}
        >
          도입문의
        </Button>
      </Box>
    </>
  );
};

export default ExperienceButton;
