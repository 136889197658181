import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {  AppDispatch, RootState } from "@/index";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import NoticeBoardDetailForm from "../../components/noticeBoard/noticeBoardListDetailForm";
import { deleteNoticeBoard, fetchNoticeBoard, fetchNoticeBoardList } from "../../redux/noticeBoard/noticeBoardActions";

type RouteParams = {
  [key: string]: string | undefined;
};

const NoticeBoardDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams<RouteParams>();
  const noticeBoardId = String(id);

  const { noticeBoard, loading, error } = useSelector(
    (state: RootState) => state.noticeBoard
  );
  useEffect(() => {
    console.log("noticeBoardId:", noticeBoardId);
    if (noticeBoardId && noticeBoardId !== "") {
      dispatch(fetchNoticeBoard(noticeBoardId));
    }
  }, [dispatch, noticeBoardId]);


  const handleEdit = () => {
    navigate(`/notice-board-edit/${noticeBoardId}`);
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this notice?")) {
      dispatch(deleteNoticeBoard(noticeBoardId))
        .then(() => {
          
          dispatch(fetchNoticeBoardList());
          navigate(`/notice-board-list`);
        })
        .catch((error: any) => {
          console.error("Error while deleting user:", error);
        });
    }
  };


  console.log("NoticeBoardDetailpage 렌더링");

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (!noticeBoard) return <div>No user Found</div>;

  return (
    <div className="container mt-5">
      <NoticeBoardDetailForm
        noticeBoard={noticeBoard}
        onEdit={handleEdit}
        onDelete={handleDelete}
      ></NoticeBoardDetailForm>
    </div>
  );
};

export default NoticeBoardDetailPage;
