import React, { useEffect } from "react";

interface PromoWindowProps {
  imageUrl: string;
  title?: string;
  linkUrl?: string;
  windowWidth?: number;  // 추가된 창의 폭 설정
  windowHeight?: number; // 추가된 창의 높이 설정
}

const PromoWindow: React.FC<PromoWindowProps> = ({
  imageUrl,
  title = "Promotion",
  linkUrl = "/sign-up",
  windowWidth = 400, // 기본값 예시
  windowHeight = 550 // 기본값 예시
}) => {
  useEffect(() => {
    const openWindow = () => {
      const promoWindow = window.open("", "_blank", `scrollbars=no,resizable=no,width=${windowWidth},height=${windowHeight}`);

      if (promoWindow) {
        promoWindow.document.write(`
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${title}</title>
            <style>
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                background-color: #f4f4f4;
                overflow: hidden;
                position: relative;
              }
              img {
                max-width: 100%;
                height: auto;
              }
              #closeButton {
                position: absolute;
                top: 20px;
                right: 20px;
                background-color: gray;
                color: white;
                border: none;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                font-size: 28px;
                font-weight: bold;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              #closeButton:hover {
                background-color: darkgray;
              }
            </style>
          </head>
          <body>
            <button id="closeButton">X</button>
            <img id="promoImage" src="${imageUrl}" alt="홍보 이미지" />
          </body>
          </html>
        `);

        promoWindow.document.close();

        const closeButton = promoWindow.document.getElementById(
          "closeButton"
        ) as HTMLButtonElement;

        const promoImage = promoWindow.document.getElementById(
          "promoImage"
        ) as HTMLImageElement;

        // 닫기 버튼 클릭 이벤트
        closeButton.addEventListener("click", () => {
          promoWindow.close();
        });

        // 이미지 하단 중앙 커서 및 클릭 이벤트
        const handleMouseMove = (event: MouseEvent) => {
          const rect = promoImage.getBoundingClientRect();
          const clickY = event.clientY - rect.top; // 클릭된 Y 좌표
          const clickX = event.clientX - rect.left; // 클릭된 X 좌표

          const clickThresholdY = rect.height * 0.15; // 하단 15% 영역
          const clickThresholdX = rect.width * 0.35; // 좌우 중앙 30% 영역

          const isInVerticalBounds = clickY > rect.height - clickThresholdY;
          const isInHorizontalBounds =
            clickX > clickThresholdX && clickX < rect.width - clickThresholdX;

          if (isInVerticalBounds && isInHorizontalBounds) {
            promoImage.style.cursor = "pointer";
          } else {
            promoImage.style.cursor = "default";
          }
        };

        promoImage.addEventListener("mousemove", handleMouseMove);

        promoImage.addEventListener("click", (event: MouseEvent) => {
          const rect = promoImage.getBoundingClientRect();
          const clickY = event.clientY - rect.top;
          const clickX = event.clientX - rect.left;

          const clickThresholdY = rect.height * 0.15;
          const clickThresholdX = rect.width * 0.35;

          const isInVerticalBounds = clickY > rect.height - clickThresholdY;
          const isInHorizontalBounds =
            clickX > clickThresholdX && clickX < rect.width - clickThresholdX;

          if (isInVerticalBounds && isInHorizontalBounds) {
            promoWindow.open(linkUrl, "_blank");
            promoWindow.close();
          }
        });

        // 이미지 로드 후 창 크기 조정
        promoImage.onload = () => {
          const { width, height } = promoImage.getBoundingClientRect();
          promoWindow.resizeTo(
            width + 16, // 창 테두리 여유 추가
            height + 39 // 창 헤더 여유 추가
          );
          promoWindow.moveTo(
            (window.screen.width - width) / 2,
            (window.screen.height - height) / 2
          );
        };
      }
    };

    openWindow();
  }, [imageUrl, title, linkUrl]);

  return null;
};

export default PromoWindow;
